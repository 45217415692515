import { FC, useEffect, useRef, useState } from 'react';
import createOasisPlayer from '@p7s1/oasis-player';
import { PlayerConfig, ContentResource, PlayerApi } from '@p7s1/oasis-types';
import { enrichContentResource } from '../utils/player';

import styles from './OasisPlayer.module.scss';

export type OasisPlayerProps = {
  config?: Omit<PlayerConfig, 'element'>;
  content: Partial<ContentResource> | Partial<ContentResource>[];
  ads?: boolean;
  onAdRollStart?: () => void;
  onAdRollEnd?: () => void;
  onPlaylistIndexChanged?: (index: number, length: number) => void;
  onPlaylistEnd?: () => void;
};

export const OasisPlayer: FC<OasisPlayerProps> = ({
  config = {
    id: 'prosieben-de-interaction',
  },
  content,
  ads,
  onAdRollStart,
  onAdRollEnd,
  onPlaylistIndexChanged,
  onPlaylistEnd,
}) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const playerApi = useRef<PlayerApi | null>(null);

  useEffect(() => {
    if (element) {
      createOasisPlayer({
        ...config,
        element,
      }).then((api) => {
        api.addContent(enrichContentResource(content, ads));
        api.setPlaylistIndex(0);
        api.on(api.EVENT.AD_ROLL_STARTED, () => {
          onAdRollStart?.();
        });
        api.on(api.EVENT.AD_ROLL_FINISHED, () => {
          onAdRollEnd?.();
        });
        api.on(
          api.EVENT.PLAYLIST_INDEX_CHANGED,
          ({ playlistIndex, playlistLength }) => {
            onPlaylistIndexChanged?.(playlistIndex, playlistLength);
          },
        );
        api.on(api.EVENT.PLAYLIST_END, () => {
          onPlaylistEnd?.();
        });
        api.play();
        playerApi.current = api;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    return () => {
      playerApi.current?.pause();
      playerApi.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={styles.player}
      data-content-id={
        Array.isArray(content)
          ? content.map((item) => item.id).join('|')
          : content.id
      }>
      <div
        ref={(node) => setElement(node)}
        className={styles.player__element}
      />
    </div>
  );
};
