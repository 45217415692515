import { Environment } from '@data/eve-sdk-web';
import { Config } from '@data/eve-sdk-web-ui-types';
import { createContext, useContext } from 'react';
import { getSdkImage } from './images';

export const defaultConfig: Config = {
  env: 'prod',
  miraToken: '',
  name: '',
  context: '',
  variant: 'inline',
  group: undefined,
  video: undefined,
  theme: 'default-dark',
  notification: undefined,
  integration: undefined,
  custom: undefined,
} as const;

export const ConfigContext = createContext<Config | undefined>(undefined);

export const useConfig = (): Config =>
  useContext(ConfigContext) ?? defaultConfig;

export const deriveEnvironmentFromIntegration = (): Environment => {
  if (document.location.hostname.startsWith('integration.')) {
    return 'int';
  }

  if (document.location.hostname.startsWith('preprod.')) {
    return 'preprod';
  }

  return 'prod';
};

export const deriveConfigFromIntegration = (): Partial<Config> => {
  const env = deriveEnvironmentFromIntegration();

  // ZAPPN BSF
  if (
    document.location.hostname.endsWith('zappn.tv') &&
    document.location.pathname.startsWith('/bsf-at')
  ) {
    return {
      custom: {
        laneBadge: 'Jetzt mitmachen',
        laneBadgeChange: 'Antwort ändern',
        laneBadgeReveal: 'Ergebnisse anzeigen',
      },
    };
  }

  // ZAPPN
  if (document.location.hostname.endsWith('zappn.tv')) {
    return {
      custom: {
        laneBadge: 'Jetzt mitmachen',
        laneBadgeChange: 'Stimme ändern',
        laneBadgeReveal: 'Ergebnisse anzeigen',
        laneBadgeStopped: 'Das Wissen war mit dir!',
      },
    };
  }

  // TVK
  if (
    document.location.pathname.startsWith('/voting-test-123') ||
    document.location.pathname.startsWith('/cwa') ||
    document.location.pathname.startsWith('/testvoting')
  ) {
    return {
      env,
      context: 'tvk-s10',
      variant: 'battle-lane',
      theme: 'tvk-dark',
      group: 'The Voice Kids',
      integration: 'magellan',
      custom: {
        battleSomFrameFallback: true,
        battleFloatingTeaser: true,
        battleFloatingTeaserId: 'tvk-final',
        battleFloatingTeaserTitleAnonymous:
          'Wer soll The Voice Kids 2022 gewinnen?',
        battleFloatingTeaserTitleUser:
          '{{name}}, wer soll The Voice Kids 2022 gewinnen?',
        battleFloatingTeaserImage: getSdkImage(env, 'the-voice-kids.jpg'),
        battleFloatingTeaserAction: 'Jetzt abstimmen',
        battleVotingHeadlineUser:
          '{{name}}, wer soll The Voice Kids 2022 gewinnen?',
        battleVotingHeadlineAnonymous: 'Wer soll The Voice Kids 2022 gewinnen?',
        battleVotingContinueAction: 'Stimme bestätigen',
        battleVotingCancelAction: 'Nein, doch nicht',
        battleThankYouStepHeadlinUser: 'Danke {{name}}!',
        battleThankYouStepHeadlineAnonymous: 'Danke!',
        battleThankYouStepText:
          'Danke für Deine Stimme. Jetzt heißt es Daumen drücken für Deinen Favoriten!',
        battleThankYouProceedAction: 'Cool, weiter',
        battleRaffleId: env === 'prod' ? 'S1TVKFinale' : 'flowtesting',
        battleRaffleProvider: 'gewinnarena',
        battleRaffleImage: 'tvk-raffle-2022.jpg',
        battleRaffleTitle: 'Deine Belohnung',
        battleRaffleText:
          'Gewinne 20.000 Euro + 2.500 Euro Gutschein (für Produkte der Marke Outflexx)',
        battleRaffleLegal:
          'Das Gewinnspiel wird dir bereitgestellt von <a href="https://www.gewinnarena.de/tv/sat1/the-voice-kids/20-000-euro-per-blitzueberweisung-2-500-gutschein/?utm_source=browser&amp;utm_medium=sat1&amp;utm_campaign=sat1api" target="_blank">Gewinnarena</a>. Du kannst über diesen Weg nach dem Voting einmalig und kostenlos am Gewinnspiel teilnehmen.<br /><br />Es gelten unsere <a href="https://www.gewinnarena.de/info/teilnahmebedingungen/?utm_source=browser&amp;utm_medium=sat1&amp;utm_campaign=sat1apiteilnahmebedingung" target="_blank">Teilnahmebedingungen</a> sowie <a href="https://www.gewinnarena.de/info/datenschutz/?utm_source=browser&amp;utm_medium=sat1&amp;utm_campaign=sat1apidatenschutz" target="_blank">Datenschutzbestimmungen</a>. Weitere Teilnahmemöglichkeiten am Gewinnspiel findest du auf <a href="https://www.gewinnarena.de/tv/sat1/the-voice-kids/20-000-euro-per-blitzueberweisung-2-500-gutschein/?utm_source=browser&amp;utm_medium=sat1&amp;utm_campaign=sat1api" target="_blank">Gewinnarena</a>.<br /><br />Teilnahme + Gewinnausschüttung ab 14 Jahren.',
        battleRaffleProceedAction: 'Ja, ich will!',
        battleRaffleRevertAction: 'Nein, Danke',
        battleAuthWallHeadline:
          'Bitte melde Dich an, um Deine Stimme zu bestätigen.',
        battleAuthWallText:
          'Damit garantieren wir, dass die Abstimmung fair verläuft.',
        battleAuthWallContinueAction: 'Jetzt anmelden',
        battleAuthWallCancelAction: 'Nicht abstimmen',
        battleResultsModalHeadline: 'So habt ihr abgestimmt.',
        battleResultsModalSublineUser: 'So habt ihr abgestimmt, {{name}}',
        battleResultsModalSublineAnonymous: 'So habt ihr abgestimmt.',
        battleVoteChangeHeadlineAnonymous:
          'Du kannst nur einem Talent Deine Stimme geben.',
        battleVoteChangeHeadlineUser:
          'Hey {{name}}, Du kannst nur einem Talent Deine Stimme geben.',
        battleVoteChangeText:
          'Möchtest Du Deine Stimme lieber {{currentItem}} geben?',
        battleVoteChangeContinueAction: 'Ja, Stimme anpassen',
        battleVoteChangeCancelAction: 'Doch nicht',
      },
    };
  }

  return {};
};
