import { FC } from 'react';
import { Voting } from '@data/eve-sdk-web';

import { useStep } from '../utils/step';
import { useConfig } from '../utils/config';
import { Button } from '../components/Button';
import { Headline } from '../components/Headline';
import { getVotingStep, isRevealed, isVoteable } from '../utils/votings';
import { getColorForImageElement, getImageUrl } from '../utils/images';
import { useColor } from '../utils/colors';

import styles from './Teaser.module.scss';

export type TeaserProps = {
  voting: Voting;
};

export const Teaser: FC<TeaserProps> = ({ voting }) => {
  const { goToNextStep } = useStep();
  const { name, group } = useConfig();
  const { setColor } = useColor();
  const item = getVotingStep(voting);
  const hasUserVote = (item?.dimensions ?? []).some(
    (dimension) => dimension.userVote,
  );

  return (
    <div className={styles.teaser}>
      {voting.image ? (
        <div className={styles['teaser__visual']}>
          <img
            crossOrigin="anonymous"
            src={getImageUrl(voting.image.url, 'splash')}
            alt={voting.title}
            className={styles['teaser__image']}
            onLoad={async (e) => {
              const averageColorResult = await getColorForImageElement(
                e.target as HTMLImageElement,
              );
              setColor(averageColorResult);
            }}
          />
        </div>
      ) : null}
      <div className={styles['teaser__content']}>
        {group ? (
          <div className={styles['teaser__topline']}>
            <Headline level={4} align="center">
              {group}
            </Headline>
          </div>
        ) : null}
        <div className={styles['teaser__title']}>
          <Headline level={1} align="center">
            {name ? `Hallo ${name}! ` : null}
            {voting.title}
          </Headline>
        </div>
        <div className={styles['teaser__action']}>
          {isVoteable(voting) ? (
            <Button
              label={hasUserVote ? 'Stimme ändern' : 'Jetzt abstimmen'}
              onClick={() => goToNextStep()}
            />
          ) : isRevealed(voting) ? (
            <Button label="Zu den Ergebnissen" onClick={() => goToNextStep()} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
