import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useElementVisibility = <T extends HTMLElement>(
  options: IntersectionObserverInit = {
    rootMargin: '0px',
    threshold: 0.5,
  },
): [MutableRefObject<T | null>, boolean] => {
  const ref = useRef<T | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(Boolean(entry?.isIntersecting));
      },
      {
        root: element?.closest('[data-element-visibility-gate]'),
        ...options,
      },
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};
