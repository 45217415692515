import { FC, useEffect, useState } from 'react';
import { Voting } from '@data/eve-sdk-web';
import { AnimatePresence, motion } from 'framer-motion';

import { useStep } from '../utils/step';
import { useConfig } from '../utils/config';
import { Button } from '../components/Button';
import { getVotingStep, isRevealed, isVoteable } from '../utils/votings';
import { Headline } from '../components/Headline';
import { getColorForImageElement, getImageUrl } from '../utils/images';
import { useColor } from '../utils/colors';

import styles from './FloatingBar.module.scss';

export type FloatingBarProps = {
  voting: Voting;
};

export const FloatingBar: FC<FloatingBarProps> = ({ voting }) => {
  const { goToNextStep } = useStep();
  const { video, name } = useConfig();
  const { setColor } = useColor();
  const item = getVotingStep(voting);
  const hasUserVote = (item?.dimensions ?? []).some(
    (dimension) => dimension.userVote,
  );
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopUp(true);
    }, 3 * 1000);
  }, []);

  return (
    <div className={styles['floating-bar']}>
      {voting.image || video ? (
        <div
          className={styles['floating-bar__visual']}
          onClick={() => goToNextStep()}>
          {video ? (
            <video
              src={video}
              autoPlay
              loop
              muted
              playsInline
              className={styles['floating-bar__video']}
            />
          ) : null}
          {voting.image ? (
            <img
              crossOrigin="anonymous"
              src={getImageUrl(voting.image.url, 'splash')}
              alt={voting.title}
              className={styles['floating-bar__image']}
              onLoad={async (e) => {
                const averageColorResult = await getColorForImageElement(
                  e.target as HTMLImageElement,
                );
                setColor(averageColorResult);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <AnimatePresence>
        <motion.div
          animate={showPopUp ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, scale: 1, x: 0, y: 0 },
            closed: { opacity: 0, scale: 0.5, x: 50, y: 50 },
            initial: { opacity: 0, scale: 0.5, x: 50, y: 50 },
          }}
          initial={false}
          className={styles['floating-bar__content']}>
          <div className={styles['floating-bar__title']}>
            <Headline level={4}>
              {name ? `Hallo ${name}! ` : null}
              {voting.title ||
                (hasUserVote
                  ? 'Du kannst immer noch deine Stimme ändern!'
                  : 'Du kannst jetzt abstimmen!')}
            </Headline>
          </div>
          <div className={styles['floating-bar__action']}>
            {isVoteable(voting) ? (
              <Button
                variant="ghost"
                label={hasUserVote ? 'Stimme ändern' : 'Jetzt abstimmen'}
                onClick={() => goToNextStep()}
              />
            ) : isRevealed(voting) ? (
              <Button
                variant="ghost"
                label="Zu den Ergebnissen"
                onClick={() => goToNextStep()}
              />
            ) : null}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
