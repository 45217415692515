import { FC } from 'react';
import { Theme, THEMES } from '@data/eve-sdk-web-ui-types';
import { Environment, getThemeStylesheetUrls } from './themes';

export type StyleSheetsProps = {
  env?: Environment;
  version?: string;
  themes?: Theme[];
};

export const StyleSheets: FC<StyleSheetsProps> = ({
  env,
  version,
  themes = THEMES,
}) => {
  const stylesheets = getThemeStylesheetUrls({
    env,
    version,
    themes: Array.from(
      themes.includes('default-dark') ? themes : ['default-dark', ...themes],
    ),
  });
  return (
    <>
      {stylesheets.map((stylesheet) => (
        <link
          key={stylesheet}
          rel="stylesheet"
          type="text/css"
          href={stylesheet}
        />
      ))}
    </>
  );
};
