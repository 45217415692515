import { FC } from 'react';
import { Voting } from '@data/eve-sdk-web';

import { useStep } from '../utils/step';
import { Modal } from '../components/Modal';
import { Button } from '../components/Button';
import { Headline } from '../components/Headline';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { getImageUrl } from '../utils/images';
import { getVotingStep } from '../utils/votings';

import styles from './Confirmation.module.scss';

export type ConfirmationProps = {
  voting: Voting;
};

export const Confirmation: FC<ConfirmationProps> = ({ voting }) => {
  const { step, nextStep, goToStart, goToNextStep } = useStep();
  const item = getVotingStep(voting);
  const userVote = (item?.dimensions ?? []).find(
    (dimension) => dimension.userVote,
  );

  return (
    <Modal
      id="thank-you"
      open={step === 'ThankYou'}
      onClose={() => goToStart()}
      footer={
        <Button
          label={nextStep === 'Results' ? 'Zu den Ergebnissen' : 'Schließen'}
          onClick={() => {
            goToNextStep();
          }}
        />
      }>
      <div className={styles.confirmation}>
        <div className={styles.confirmation__content}>
          <Stack>
            <Headline level={4} align="center">
              Danke für deine Stimme!
            </Headline>
            <Copy align="center">
              Wir sehen uns hoffentlich in der nächsten Sendung!
            </Copy>
          </Stack>
        </div>
        <div className={styles.confirmation__visual}>
          {userVote?.image?.url ? (
            <img
              crossOrigin="anonymous"
              src={getImageUrl(userVote.image.url, 'cover')}
              alt={userVote.title}
              className={styles.confirmation__image}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
