import { FC } from 'react';

import { Stack } from './Stack';

import styles from './Ranking.module.scss';

export type RankingItemProps = {
  image?: string;
  label: string;
  active?: boolean;
};

export type RankingProps = {
  items: RankingItemProps[];
};

export const Ranking: FC<RankingProps> = ({ items }) => {
  return (
    <div className={styles.ranking}>
      <Stack>
        <div className={styles.ranking__designated}>
          {items[0].image ? (
            <img
              src={items[0].image}
              alt={items[0].label}
              className={styles.ranking__visual}
            />
          ) : null}
          <div className={styles.ranking__label}>{items[0].label}</div>
          {items[0].active ? (
            <div className={styles.ranking__subline}>Deine Stimme</div>
          ) : null}
        </div>
        <div className={styles.ranking__items}>
          {items.slice(1).map(({ image, label, active }) => (
            <div key={label} className={styles.ranking__item}>
              <div className={styles.ranking__content}>
                <div className={styles.ranking__label}>{label}</div>
                {active ? (
                  <div className={styles.ranking__subline}>Deine Stimme</div>
                ) : null}
              </div>
              {image ? (
                <img
                  src={image}
                  alt={label}
                  className={styles.ranking__visual}
                />
              ) : null}
            </div>
          ))}
        </div>
      </Stack>
    </div>
  );
};
