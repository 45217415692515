import { FC, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  addVote,
  GroupStep,
  removeVote,
  ResultsStep,
  Voting,
  VotingDimension,
  VotingStep,
} from '@data/eve-sdk-web';
import debounce from 'lodash.debounce';

import {
  getColorForImageElement,
  getImageUrl,
  getSdkImage,
  getColorForImageUrl,
} from '../utils/images';
import { getIntroStep, isRevealed, isVoteable } from '../utils/votings';
import { Modal } from './Modal';
import { Card } from './Card';
import { Headline } from './Headline';
import { Copy } from './Copy';
import { Button } from './Button';
import { Stack } from './Stack';
import { useConfig } from '../utils/config';
import { useColor } from '../utils/colors';
import { triggerAuth } from '../utils/auth';
import { getItem, removeItem, setItem } from '../utils/storage';
import { useNotification } from './Notifications';
import {
  NativeAdStep,
  injectAds,
  SomAdStep,
  modalsAds,
  ModalAd,
} from '../utils/ads';
import { OasisPlayer } from './OasisPlayer';
import { trackEvent } from '../utils/tracking';
import { useBattleLane } from '../steps/BattleLane';
import { getVideoIdForVotingDimension } from '../utils/media';
import { on, emit } from '../utils/bus';
import { getSlotNameForEnvironment, reloadDisplaySlots } from '../utils/som';
import { AdTracking } from './AdTracking';
import { isEnabled } from '../utils/toggles';
import { Trophy } from './Icon';
import { Tiles } from './Tiles';
import { participate } from '../utils/raffle';
import { interpolate } from '../utils/ui';
import { convertClipTitleToArtists } from '../utils/hacks';
import { LinkTracking } from './LinkTracking';

import styles from './BattleLaneItem.module.scss';

export type BattleLaneItemProps = {
  coverImage?: string;
  badge: string;
  voting: Voting;
  group: GroupStep;
  step: VotingStep | ResultsStep;
  index: number;
  previousStep: VotingStep | ResultsStep;
  nextStep: VotingStep | ResultsStep;
  isFinalRound?: boolean;
  stretched?: boolean;
};

export const BattleLaneItem: FC<BattleLaneItemProps> = ({
  coverImage,
  badge,
  voting,
  group,
  step,
  index,
  previousStep,
  nextStep,
  isFinalRound,
  stretched,
}) => {
  const slider = useRef<HTMLDivElement | null>(null);
  const {
    env,
    context,
    name,
    miraToken,
    notification: notificationText,
    group: configGroup,
    custom,
  } = useConfig();
  const { count: laneCount } = useBattleLane();
  const notification = useNotification();
  const { setColor } = useColor();
  const isVotingRevealed = isRevealed(voting) && !isVoteable(voting);
  const dimensions = step.dimensions;
  const [showOnboardingHint, setShowOnboardingHint] = useState<boolean>(
    !getItem(`hints:onboarding:${voting.flowId}`),
  );
  const [showRaffleHint, setShowRaffleHint] = useState<boolean>(
    !getItem(`hints:raffle:${voting.flowId}`),
  );
  const [showVotingModal, setShowVotingModal] = useState<boolean>(false);
  const [showIntroModal, setShowIntroModal] = useState<boolean>(false);
  const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false);
  const [showRaffleModal, setShowRaffleModal] = useState<boolean>(false);
  const [showAuthWall, setShowAuthWall] = useState<boolean>(false);
  const [showVoteChangeModal, setShowVoteChangeModal] =
    useState<boolean>(false);
  const [showVoteConfirmationModal, setShowVoteConfirmationModal] =
    useState<boolean>(false);
  const [currentPlayer, setCurrentPlayer] = useState<string | undefined>(
    undefined,
  );
  const [isAdRoleActive, setIsAdRoleActive] = useState<boolean>(false);
  const [currentModalAd, setCurrentModalAd] = useState<ModalAd | undefined>(
    undefined,
  );
  const [disableModalAdActions, setDisableModalAdActions] = useState<boolean>(
    modalsAds.length > 0,
  );
  const votingOptions = useMemo(
    () => injectAds(dimensions, context),
    [dimensions, context],
  );
  const [currentItemId, setCurrentItemId] = useState<string | undefined>(
    undefined,
  );
  const [currentVoteConfirmationItem, setCurrentVoteConfirmationItem] =
    useState<VotingDimension | undefined>(undefined);
  const currentItemIndex = useMemo(
    () => votingOptions.findIndex((item) => item.id === currentItemId),
    [currentItemId, votingOptions],
  );
  const currentItem: NativeAdStep | SomAdStep | VotingDimension | undefined =
    useMemo(
      () => votingOptions[currentItemIndex],
      [currentItemIndex, votingOptions],
    );
  const userVotes: string[] = useMemo(() => {
    return Array.from(new Set((step.userVote ?? []).map((vote) => vote.id)));
  }, [step]);
  const isCurrentItemVoted = useMemo(
    () =>
      currentItem
        ? currentItem instanceof NativeAdStep
          ? false
          : userVotes.includes(currentItem.id)
        : false,
    [currentItem, userVotes],
  );
  const isCurrentVoteConfirmationItemVoted = useMemo(
    () =>
      currentVoteConfirmationItem
        ? currentVoteConfirmationItem instanceof NativeAdStep
          ? false
          : userVotes.includes(currentVoteConfirmationItem.id)
        : false,
    [currentVoteConfirmationItem, userVotes],
  );
  const currentItemVotedFor = useMemo(
    () => dimensions.find((dimension) => userVotes.includes(dimension.id)),
    [userVotes, dimensions],
  );
  const introStep = isFinalRound ? undefined : getIntroStep(voting);

  const openVotingModal = () => {
    setCurrentItemId(dimensions[0].id);
    setShowVotingModal(true);
  };

  const resetVotingModalButStayInCurrentItem = () => {
    setIsAdRoleActive(false);
    setShowThankYouModal(false);
    setShowRaffleModal(false);
    setShowVoteChangeModal(false);
    setShowVoteConfirmationModal(false);
    setCurrentVoteConfirmationItem(undefined);
  };

  const resetVotingModal = () => {
    resetVotingModalButStayInCurrentItem();
    setShowVotingModal(false);
    setCurrentItemId(undefined);
    setCurrentPlayer(undefined);
  };

  const triggerNotification = (id: string, type: 'add' | 'remove') => {
    const dimension = dimensions.find((item) => item.id === id);

    notification({
      id: String(Math.random()),
      title: dimension
        ? `${type === 'remove' ? '-' : '+'}1 für ${dimension.title}`
        : name
        ? `Danke für deine Stimme, ${name}!`
        : 'Danke für deine Stimme!',
      text:
        notificationText ?? dimension
          ? name
            ? `Danke, ${name}!`
            : 'Danke für deine Stimme!'
          : 'Die Ergebnisse siehst du in Kürze.',
    });
  };

  const goToItem = async (index: number): Promise<void> => {
    const votingOption = votingOptions[index];
    const votingOptionId = votingOption.id;
    if (slider.current) {
      const item = slider.current.querySelector<HTMLDivElement>(
        `[data-id="${votingOptionId}"]`,
      );
      if (item) {
        slider.current.scrollTo({
          left: item.offsetLeft,
          behavior: 'smooth',
        });
        setCurrentItemId(votingOptionId);
        const image = item.querySelector<HTMLImageElement>('img');
        if (image) {
          try {
            const averageColorResult = await getColorForImageElement(image);
            setColor(averageColorResult);
          } catch {
            setColor(undefined);
          }
        }
      }
    }
  };

  const goToPreviousItem = (): void => {
    goToItem(Math.max(0, currentItemIndex - 1));
  };

  const goToNextItem = (): void => {
    goToItem(Math.min(votingOptions.length - 1, currentItemIndex + 1));
  };

  const goToPreviousStep = () => {
    if (previousStep.id !== step.id) {
      resetVotingModal();
      emit(`battle-lane-show-item:${previousStep.id}`);
    }
  };

  const goToNextStep = () => {
    if (previousStep.id !== step.id) {
      resetVotingModal();
      emit(`battle-lane-show-item:${nextStep.id}`);
    }
  };

  const swipeHandler = (): void => {
    if (slider.current) {
      setShowOnboardingHint(false);
      setItem(`hints:onboarding:${voting.flowId}`, true);
      Array.from(slider.current.children).forEach(async (child) => {
        if (
          slider.current &&
          Math.abs(
            child.getBoundingClientRect().left -
              slider.current.getBoundingClientRect().left,
          ) < 10
        ) {
          if (child) {
            const newDimension = child.getAttribute('data-id') ?? undefined;
            setCurrentItemId(newDimension);
            if (newDimension !== currentPlayer) {
              setCurrentPlayer(undefined);
            }
            if (newDimension) {
              const image = child.querySelector<HTMLImageElement>('img');
              if (image) {
                try {
                  const averageColorResult = await getColorForImageElement(
                    image,
                  );
                  setColor(averageColorResult);
                } catch {
                  setColor(undefined);
                }
              }
            } else {
              setColor(undefined);
            }
          }
        }
      });
    }
  };

  const debouncedScrollHandler = useMemo(
    () => debounce(swipeHandler, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPlayer],
  );

  useEffect(() => {
    on<Partial<{ dimension: string }>>(
      `battle-lane-show-item:${step.id}`,
      (payload) => {
        openVotingModal();
        if (payload?.dimension) {
          const index = votingOptions.findIndex(
            (option) => option.id === payload.dimension,
          );
          if (index > -1) {
            window.setTimeout(() => {
              goToItem(index);
            }, 250);
          }
        }
      },
    );

    if (getItem(`step:${step.id}`)) {
      emit(`battle-lane-show-item:${step.id}`, {
        dimension: getItem(`step:${step.id}`),
      });
      removeItem(`step:${step.id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.id]);

  useEffect(() => {
    if (currentItem) {
      const isNativeAdStep = currentItem instanceof NativeAdStep;
      const isSomAdStep = currentItem instanceof SomAdStep;
      const isAdStep = isNativeAdStep || isSomAdStep;
      if (!isAdStep) {
        trackEvent('Voting Teaser Impression', {
          group: currentItem.title.trim(),
          label: `${voting.title.trim()} - ${index}/${laneCount}`,
        });
      }
      if (currentItem instanceof NativeAdStep) {
        /* NOOP */
      } else if (currentItem instanceof SomAdStep) {
        const slotId = `${step.id}-${currentItem.id}`;
        window.setTimeout(() => {
          reloadDisplaySlots(
            getSlotNameForEnvironment('rectangle1'),
            `[data-ad-slot="${slotId}"]`,
          );
        }, 500);
      } else {
        const playerId = getVideoIdForVotingDimension(currentItem);
        const timer = window.setTimeout(() => {
          if (isEnabled('battleLaneAutoplay')) {
            setCurrentPlayer(playerId);
          }
        }, 1.5 * 1000);
        return () => window.clearTimeout(timer);
      }
      setIsAdRoleActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    if (showThankYouModal && modalsAds.length > 0) {
      const adTimer = window.setTimeout(() => {
        setCurrentModalAd(modalsAds?.[0]);
      }, 1.5 * 1000);
      const actionTimer = window.setTimeout(() => {
        setDisableModalAdActions(false);
      }, 3 * 1000);
      return () => {
        window.clearTimeout(adTimer);
        window.clearTimeout(actionTimer);
      };
    }
  }, [showThankYouModal]);

  return (
    <>
      <div className={styles['battle-lane-item']}>
        <Card
          flippable={false}
          coverImage={coverImage}
          icon={isVotingRevealed ? 'Trophy' : 'Videos'}
          label={voting.title}
          topline={group.subTitle}
          badge={badge}
          onCoverClick={() => {
            const key = `modals:intro:${voting.flowId}`;
            if (
              introStep &&
              !getItem(key) &&
              !custom?.battleLaneDisableIntroStep
            ) {
              setItem(key, true);
              setShowIntroModal(true);
            } else {
              openVotingModal();
            }
            trackEvent('Voting Teaser Click', {
              group: String(configGroup),
              label: `${voting.title.trim()} (${group.subTitle.trim()}) - ${index}/${laneCount}`,
            });
          }}
          stretched={stretched}
        />
      </div>
      {introStep ? (
        <Modal
          id="intro"
          variant="medium"
          open={showIntroModal}
          onClose={() => setShowIntroModal(false)}
          coverImage={getSdkImage(env, 'the-voice-kids.jpg')}
          footer={
            <Button
              label={String(custom?.battleLaneIntroStepAction)}
              onClick={() => {
                openVotingModal();
                setShowIntroModal(false);
              }}
            />
          }>
          <Stack gap="xs">
            <Headline level={4} align="center">
              {custom?.battleLaneIntroStepHeadline}
            </Headline>
            <Copy align="center">{custom?.battleLaneIntroStepText}</Copy>
          </Stack>
        </Modal>
      ) : null}
      {isFinalRound ? (
        <Modal
          id="thank-you"
          variant="medium"
          open={showThankYouModal}
          onClose={() => setShowThankYouModal(false)}
          coverImage={
            currentItemVotedFor?.image?.url
              ? getImageUrl(currentItemVotedFor.image.url, 'splash')
              : getSdkImage(env, 'the-voice-kids.jpg')
          }
          ad={currentModalAd}
          footer={
            <Stack gap="xs" align="center">
              <Button
                disabled={disableModalAdActions}
                label={String(custom?.battleLaneThankYouProceedAction)}
                onClick={() => {
                  setShowThankYouModal(false);
                  if (showRaffleHint) {
                    setShowRaffleModal(true);
                  } else {
                    resetVotingModalButStayInCurrentItem();
                  }
                }}
              />
              {!isFinalRound ? (
                <Button
                  variant="ghost"
                  disabled={disableModalAdActions}
                  label={String(custom?.battleLaneThankYouRevertAction)}
                  onClick={async () => {
                    if (isCurrentItemVoted) {
                      await removeVote(
                        context,
                        group.bucketId,
                        currentItem.id,
                        1,
                      );
                    } else {
                      await addVote(context, group.bucketId, currentItem.id, 1);
                    }
                    triggerNotification(
                      currentItem.id,
                      isCurrentItemVoted ? 'remove' : 'add',
                    );
                    setShowThankYouModal(false);
                    resetVotingModalButStayInCurrentItem();
                  }}
                />
              ) : null}
            </Stack>
          }>
          <Stack gap="xs">
            <div className={styles['battle-lane-item__intro-icon']}>
              <Trophy size={48} />
            </div>
            <Headline level={4} align="center">
              {name
                ? interpolate(
                    String(custom?.battleLaneThankYouStepHeadlinUser),
                    {
                      name,
                    },
                  )
                : String(custom?.battleLaneThankYouStepHeadlineAnonymous)}
            </Headline>
            <Copy align="center">
              {isFinalRound
                ? custom?.battleLaneThankYouStepTextFinal
                : custom?.battleLaneThankYouStepText}
            </Copy>
          </Stack>
        </Modal>
      ) : null}
      {isFinalRound && custom?.battleLaneRaffleId ? (
        <Modal
          id="raffle"
          variant="medium"
          open={showRaffleModal}
          onClose={() => setShowRaffleModal(false)}
          coverImage={getSdkImage(env, 'the-voice-kids-raffle.jpg')}
          footer={
            <Stack gap="xs" align="center">
              <Button
                label={String(custom?.battleLaneRaffleProceedAction)}
                onClick={async () => {
                  setItem(`hints:raffle:${voting.flowId}`, true);
                  setShowRaffleHint(false);
                  if (miraToken && custom?.battleLaneRaffleId) {
                    await participate(
                      String(custom.battleLaneRaffleId),
                      miraToken,
                    );
                    notification({
                      id: String(Math.random()),
                      title: name
                        ? `Danke für deine Teilnahme, ${name}!`
                        : 'Danke für deine Teilnahme!',
                      text: 'Wir drücken die Daumen!',
                    });
                  }
                  setShowRaffleModal(false);
                  resetVotingModalButStayInCurrentItem();
                }}
              />
              <Button
                variant="ghost"
                label={String(custom?.battleLaneRaffleRevertAction)}
                onClick={async () => {
                  setItem(`hints:raffle:${voting.flowId}`, true);
                  setShowRaffleHint(false);
                  setShowRaffleModal(false);
                  resetVotingModalButStayInCurrentItem();
                }}
              />
            </Stack>
          }>
          <Stack gap="xs">
            <div className={styles['battle-lane-item__intro-icon']}>
              <Trophy size={48} />
            </div>
            <Headline level={4} align="center">
              {custom?.battleLaneRaffleTitle}
            </Headline>
            <Copy align="center">{custom?.battleLaneRaffleText}</Copy>
            <Copy align="center" size="tiny">
              <LinkTracking>
                <span
                  dangerouslySetInnerHTML={{
                    __html: String(custom?.battleLaneRaffleLegal),
                  }}
                />
              </LinkTracking>
            </Copy>
          </Stack>
        </Modal>
      ) : null}
      <Modal
        id="voting"
        open={showVotingModal}
        onClose={() => {
          resetVotingModal();
        }}
        seamless>
        <div className={styles['battle-lane-item__wrapper']}>
          <div className={styles['battle-lane-item__overlay']}>
            <div className={styles['battle-lane-item__controls']}>
              <div
                className={classNames(
                  styles['battle-lane-item__control'],
                  styles['battle-lane-item__control--side'],
                )}>
                <Button
                  disabled={isAdRoleActive}
                  icon="ArrowLeft"
                  variant="ghost"
                  onClick={() => {
                    if (currentItemIndex <= 0) {
                      goToPreviousStep();
                    } else {
                      goToPreviousItem();
                    }
                    trackEvent('Voting Pagination Previous Click', {
                      group: String(configGroup),
                      label: `${voting.title.trim()} (${group.subTitle.trim()}) - ${index}/${laneCount}`,
                    });
                  }}
                />
              </div>
              <div
                className={classNames(
                  styles['battle-lane-item__control'],
                  styles['battle-lane-item__control--main'],
                )}>
                {currentItem instanceof NativeAdStep ? (
                  <Button
                    disabled={isAdRoleActive}
                    size="big"
                    colors={currentItem.colors.button}
                    label={currentItem.ctaTitle}
                    onClick={() => {
                      trackEvent('Ad Click', {
                        group: `${currentItemIndex + 1}_${
                          currentItem.tracking.name
                        }`,
                        label: document.location.pathname,
                      });
                      currentItem.onClick();
                    }}
                  />
                ) : currentItem instanceof SomAdStep ? (
                  <Button disabled size="big" icon="Like" />
                ) : (
                  <Button
                    disabled={
                      isAdRoleActive || (isFinalRound && isCurrentItemVoted)
                    }
                    size="big"
                    variant={isCurrentItemVoted ? 'ghost' : 'prominent'}
                    icon={
                      custom?.battleLaneHideActionIcons
                        ? undefined
                        : isCurrentItemVoted
                        ? 'Checkmark'
                        : isFinalRound
                        ? 'Trophy'
                        : 'Like'
                    }
                    label={
                      isCurrentItemVoted
                        ? isFinalRound
                          ? custom?.battleLaneVotingActionLabelVotedFinal
                            ? String(
                                custom?.battleLaneVotingActionLabelVotedFinal,
                              )
                            : undefined
                          : custom?.battleLaneVotingActionLabelVoted
                          ? String(custom?.battleLaneVotingActionLabelVoted)
                          : undefined
                        : isFinalRound
                        ? custom?.battleLaneVotingActionLabelFinal
                          ? String(custom?.battleLaneVotingActionLabelFinal)
                          : undefined
                        : custom?.battleLaneVotingActionLabel
                        ? String(custom?.battleLaneVotingActionLabel)
                        : undefined
                    }
                    onClick={async () => {
                      if (currentItem) {
                        if (
                          custom?.battleLaneVotingConfirmation &&
                          !getItem(`vote-confirmation:${voting.flowId}`)
                        ) {
                          setShowVoteConfirmationModal(true);
                          setItem(`vote-confirmation:${voting.flowId}`, true);
                        } else {
                          if (
                            isFinalRound &&
                            userVotes.length > 0 &&
                            !isCurrentItemVoted
                          ) {
                            setShowVoteChangeModal(true);
                          } else {
                            if (miraToken) {
                              if (isCurrentItemVoted) {
                                await removeVote(
                                  context,
                                  group.bucketId,
                                  currentItem.id,
                                  1,
                                );
                              } else {
                                await addVote(
                                  context,
                                  group.bucketId,
                                  currentItem.id,
                                  1,
                                );
                              }
                              removeItem(`step:${step.id}`);
                              if (isFinalRound) {
                                setShowThankYouModal(true);
                              } else {
                                triggerNotification(
                                  currentItem.id,
                                  isCurrentItemVoted ? 'remove' : 'add',
                                );
                              }
                              trackEvent('Voting Click', {
                                group: currentItem.title.trim(),
                                label: `${voting.title.trim()} - ${index}/${laneCount}`,
                              });
                            } else {
                              setItem(`step:${step.id}`, currentItem.id);
                              setShowAuthWall(true);
                              trackEvent('Auth Wall Show', {
                                group: currentItem.title.trim(),
                                label: `${voting.title.trim()} - ${index}/${laneCount}`,
                              });
                            }
                          }
                        }
                      }
                    }}
                  />
                )}
              </div>
              <div
                className={classNames(
                  styles['battle-lane-item__control'],
                  styles['battle-lane-item__control--side'],
                )}>
                <Button
                  disabled={isAdRoleActive}
                  icon="ArrowRight"
                  variant="ghost"
                  onClick={() => {
                    if (currentItemIndex >= votingOptions.length - 1) {
                      goToNextStep();
                    } else {
                      goToNextItem();
                    }
                    trackEvent('Voting Pagination Next Click', {
                      group: String(configGroup),
                      label: `${voting.title.trim()} (${group.subTitle.trim()}) - ${index}/${laneCount}`,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div
            ref={slider}
            className={styles['battle-lane-item__dimensions']}
            onScroll={debouncedScrollHandler}
            data-element-visibility-gate>
            {votingOptions.map((votingOption, index) => {
              const isNativeAdStep = votingOption instanceof NativeAdStep;
              const isSomAdStep = votingOption instanceof SomAdStep;
              const isAdStep = isNativeAdStep || isSomAdStep;
              const dimensionIndex = dimensions.findIndex(
                (item) => item.id === votingOption.id,
              );
              return (
                <div
                  key={votingOption.id}
                  className={styles['battle-lane-item__dimension']}
                  data-id={votingOption.id}
                  style={
                    isNativeAdStep
                      ? {
                          '--ad-background': votingOption.colors.background,
                        }
                      : undefined
                  }>
                  <div className={styles['battle-lane-item__dimension-header']}>
                    {isAdStep ? (
                      isSomAdStep ? (
                        <div
                          className={
                            styles['battle-lane-item__dimension-visual']
                          }>
                          <AdTracking
                            group={`${currentItemIndex + 1}_${
                              votingOption.tracking.name
                            }`}
                            label={document.location.pathname}>
                            <div
                              className={
                                styles['battle-lane-item__dimension-slot']
                              }>
                              <div
                                data-ad-slot={`${step.id}-${votingOption.id}`}
                                data-som-ad
                              />
                            </div>
                            <div
                              className={
                                styles['battle-lane-item__dimension-badge']
                              }>
                              Anzeige
                            </div>
                          </AdTracking>
                        </div>
                      ) : (
                        <div
                          className={
                            styles['battle-lane-item__dimension-visual']
                          }>
                          <AdTracking
                            group={`${currentItemIndex + 1}_${
                              votingOption.tracking.name
                            }`}
                            label={document.location.pathname}>
                            <img
                              crossOrigin="anonymous"
                              src={votingOption.imageUrl}
                              alt=""
                              className={
                                styles['battle-lane-item__dimension-image']
                              }
                              onClick={() => votingOption.onClick()}
                            />
                            <div
                              className={
                                styles['battle-lane-item__dimension-badge']
                              }>
                              Anzeige
                            </div>
                          </AdTracking>
                        </div>
                      )
                    ) : (
                      <div
                        className={
                          styles['battle-lane-item__dimension-visual']
                        }>
                        {currentPlayer ===
                        getVideoIdForVotingDimension(votingOption) ? (
                          <div
                            className={classNames(
                              styles['battle-lane-item__dimension-player'],
                              {
                                [styles[
                                  'battle-lane-item__dimension-player--ad'
                                ]]: isAdRoleActive,
                              },
                            )}>
                            <OasisPlayer
                              content={{
                                id: currentPlayer,
                              }}
                              onAdRollStart={() => setIsAdRoleActive(true)}
                              onAdRollEnd={() => setIsAdRoleActive(false)}
                              onPlaylistEnd={() => {
                                if (
                                  currentItemIndex >=
                                  votingOptions.length - 1
                                ) {
                                  goToNextStep();
                                } else {
                                  goToNextItem();
                                }
                              }}
                              ads={
                                dimensionIndex > -1 &&
                                (dimensionIndex + 1) % 3 === 0
                              }
                            />
                          </div>
                        ) : (
                          <img
                            crossOrigin="anonymous"
                            src={
                              votingOption.image
                                ? getImageUrl(votingOption.image.url, 'splash')
                                : undefined
                            }
                            alt=""
                            className={
                              styles['battle-lane-item__dimension-image']
                            }
                            onClick={() =>
                              setCurrentPlayer(
                                getVideoIdForVotingDimension(votingOption),
                              )
                            }
                            onLoad={async (e) => {
                              if (index === 0) {
                                try {
                                  const averageColorResult =
                                    await getColorForImageElement(
                                      e.target as HTMLImageElement,
                                    );
                                  setColor(averageColorResult);
                                } catch {
                                  setColor(undefined);
                                }
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                    <div
                      className={styles['battle-lane-item__dimension-intro']}>
                      <div
                        className={
                          styles['battle-lane-item__dimension-intro-content']
                        }>
                        {isNativeAdStep ? (
                          <Stack gap="xs">
                            <Headline level={4} align="center" clamped>
                              {votingOption.title}
                            </Headline>
                            <Headline level={6} align="center" clamped>
                              {votingOption.subTitle}
                            </Headline>
                          </Stack>
                        ) : isSomAdStep ? (
                          <Stack gap="xs">
                            <Headline level={4} align="center" clamped>
                              {votingOption.title}
                            </Headline>
                            <Headline level={6} align="center" clamped>
                              {votingOption.subTitle}
                            </Headline>
                          </Stack>
                        ) : (
                          <Stack gap="xs">
                            <Headline level={4} align="center" clamped>
                              {votingOption.title}
                            </Headline>
                            {group.subTitle ? (
                              <Headline level={6} align="center" clamped>
                                {custom?.battleLaneSubTitlePrefix
                                  ? `${custom?.battleLaneSubTitlePrefix} ${group.subTitle}`
                                  : group.subTitle}
                              </Headline>
                            ) : null}
                          </Stack>
                        )}
                      </div>
                    </div>
                  </div>
                  {isSomAdStep ? null : votingOption?.description ? (
                    <div
                      className={styles['battle-lane-item__dimension-content']}>
                      <Stack gap="xl" align="center">
                        {currentItemIndex === 0 &&
                        showOnboardingHint &&
                        !custom?.battleLaneDisableOnboarding ? (
                          <div
                            className={
                              styles['battle-lane-item__dimension-hint']
                            }>
                            <Stack gap="xs">
                              <Headline level={6}>
                                {isFinalRound
                                  ? name
                                    ? interpolate(
                                        String(
                                          custom?.battleLaneOnboardingHeadlineFinalUser,
                                        ),
                                        {
                                          name,
                                        },
                                      )
                                    : String(
                                        custom?.battleLaneOnboardingHeadlineFinalAnonymous,
                                      )
                                  : name
                                  ? interpolate(
                                      String(
                                        custom?.battleLaneOnboardingHeadlineUser,
                                      ),
                                      {
                                        name,
                                      },
                                    )
                                  : String(
                                      custom?.battleLaneOnboardingHeadlineAnonymous,
                                    )}
                              </Headline>
                              <Copy>
                                {isFinalRound
                                  ? custom?.battleLaneOnboardingTextFinal
                                  : custom?.battleLaneOnboardingText}
                              </Copy>
                            </Stack>
                          </div>
                        ) : null}
                        <div
                          className={
                            styles['battle-lane-item__dimension-description']
                          }>
                          {votingOption.description}
                        </div>
                      </Stack>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        id="auth"
        variant="small"
        align="center"
        open={showAuthWall}
        onClose={() => {
          removeItem(`step:${step.id}`);
          setShowAuthWall(false);
        }}>
        <Stack>
          <svg
            viewBox="0 0 52 64"
            className={styles['battle-lane-item__auth-wall-illustration']}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.5 6.74994C19.0442 6.74994 13 12.7941 13 20.2499C13 27.7058 19.0442 33.7499 26.5 33.7499C33.9558 33.7499 40 27.7058 40 20.2499C40 12.7941 33.9558 6.74994 26.5 6.74994ZM26.5 9.74994C32.299 9.74994 37 14.451 37 20.2499C37 26.0489 32.299 30.7499 26.5 30.7499C20.701 30.7499 16 26.0489 16 20.2499C16 14.451 20.701 9.74994 26.5 9.74994Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.1466 38.2499C42.5444 38.2499 50.3855 45.7558 51.4906 55.5823C51.5832 56.4055 50.9909 57.148 50.1677 57.2405C49.3444 57.3331 48.602 56.7408 48.5094 55.9176C47.5833 47.6826 41.1536 41.4258 33.4719 41.2536L33.1466 41.2499H18.8508C11.0334 41.2499 4.43862 47.555 3.49042 55.8962C3.39684 56.7193 2.65371 57.3107 1.83059 57.2171C1.00746 57.1236 0.416042 56.3804 0.509614 55.5573C1.61226 45.8576 9.27831 38.4265 18.5201 38.253L18.8508 38.2499H33.1466Z"
              fill="currentColor"
            />
          </svg>
          <Headline level={5} align="center">
            {custom?.battleLaneAuthWallHeadline}
          </Headline>
          <Copy size="medium" align="center">
            {custom?.battleLaneAuthWallText}
          </Copy>
          <Stack align="center" gap="xs">
            <Button
              label={String(custom?.battleLaneAuthWallContinueAction)}
              onClick={async () => {
                triggerAuth();
              }}
            />
            <Button
              variant="subtle"
              label={String(custom?.battleLaneAuthWallCancelAction)}
              onClick={async () => {
                removeItem(`step:${step.id}`);
                setShowAuthWall(false);
              }}
            />
          </Stack>
        </Stack>
      </Modal>
      {currentItem ? (
        <Modal
          id="vote-change"
          variant="small"
          align="center"
          open={showVoteChangeModal}
          onClose={() => {
            setShowVoteChangeModal(false);
          }}>
          <Stack>
            <Headline level={5} align="center">
              {name
                ? interpolate(
                    String(custom?.battleLaneVoteChangeHeadlineUser),
                    {
                      name,
                    },
                  )
                : custom?.battleLaneVoteChangeHeadlineAnonymous}
            </Headline>
            <Copy size="medium" align="center">
              {interpolate(String(custom?.battleLaneVoteChangeText), {
                currentItem: convertClipTitleToArtists(
                  custom?.battleLaneVotingConfirmation &&
                    currentVoteConfirmationItem
                    ? currentVoteConfirmationItem.title
                    : currentItem.title,
                ),
              })}
            </Copy>
            <Stack align="center" gap="xs">
              <Button
                icon="Trophy"
                label={String(custom?.battleLaneVoteChangeContinueAction)}
                onClick={async () => {
                  const item =
                    custom?.battleLaneVotingConfirmation &&
                    currentVoteConfirmationItem
                      ? currentVoteConfirmationItem
                      : currentItem;
                  if (miraToken) {
                    await removeVote(context, group.bucketId, userVotes[0], 1);
                    await addVote(context, group.bucketId, item.id, 1);
                    if (isFinalRound) {
                      setShowThankYouModal(true);
                    } else {
                      triggerNotification(item.id, 'add');
                    }
                    trackEvent('Voting Click', {
                      group: item.title.trim(),
                      label: `${voting.title.trim()} - ${index}/${laneCount}`,
                    });
                  }
                }}
              />
              <Button
                variant="subtle"
                label={String(custom?.battleLaneVoteChangeCancelAction)}
                onClick={async () => {
                  setShowVoteChangeModal(false);
                }}
              />
            </Stack>
          </Stack>
        </Modal>
      ) : null}
      {currentItem ? (
        <Modal
          id="vote-confirmation"
          variant="medium"
          align="center"
          open={showVoteConfirmationModal}
          onClose={() => {
            setShowVoteConfirmationModal(false);
          }}
          footer={
            <Stack align="center" gap="xs">
              <Button
                disabled={!currentVoteConfirmationItem}
                label="Stimme bestätigen"
                onClick={async () => {
                  if (currentVoteConfirmationItem) {
                    if (miraToken) {
                      if (
                        isFinalRound &&
                        userVotes.length > 0 &&
                        !isCurrentItemVoted
                      ) {
                        setShowVoteChangeModal(true);
                      } else {
                        await removeVote(
                          context,
                          group.bucketId,
                          userVotes[0],
                          1,
                        );
                        await addVote(
                          context,
                          group.bucketId,
                          currentItem.id,
                          1,
                        );
                        if (isFinalRound) {
                          setShowThankYouModal(true);
                        } else {
                          triggerNotification(currentItem.id, 'add');
                          resetVotingModalButStayInCurrentItem();
                        }
                        trackEvent('Voting Click', {
                          group: currentItem.title.trim(),
                          label: `${voting.title.trim()} - ${index}/${laneCount}`,
                        });
                      }
                    } else {
                      setItem(
                        `step:${step.id}`,
                        currentVoteConfirmationItem.id,
                      );
                      setShowAuthWall(true);
                      trackEvent('Auth Wall Show', {
                        group: currentVoteConfirmationItem.title.trim(),
                        label: `${voting.title.trim()} - ${index}/${laneCount}`,
                      });
                    }
                  }
                }}
              />
              <Button
                variant="subtle"
                label="Nein, doch nicht"
                onClick={async () => {
                  setShowVoteConfirmationModal(false);
                }}
              />
            </Stack>
          }>
          <Stack gap="xl" variant="medium">
            <Stack gap="xs">
              <Headline level={4} align="center">
                {name
                  ? `${name}, wer soll mit dem Community Ticket direkt ins Finale einziehen?`
                  : 'Wer soll mit dem Community Ticket direkt ins Finale einziehen?'}
              </Headline>
              <Headline level={6} align="center">
                Vote jetzt für Deine Favoritin!
              </Headline>
            </Stack>
            <Stack variant="narrow">
              <Tiles
                items={dimensions.map((dimension) => ({
                  image: dimension.image?.url
                    ? getImageUrl(dimension.image.url, 'square')
                    : undefined,
                  label: convertClipTitleToArtists(dimension.title),
                  active: currentVoteConfirmationItem?.id === dimension.id,
                  disabled: isCurrentVoteConfirmationItemVoted,
                  onClick: async () => {
                    if (currentVoteConfirmationItem?.id === dimension.id) {
                      if (voting.image) {
                        const averageColorResult = await getColorForImageUrl(
                          getImageUrl(voting.image.url, 'splash'),
                        );
                        setColor(averageColorResult);
                      }
                      setCurrentVoteConfirmationItem(undefined);
                    } else {
                      if (dimension.image?.url) {
                        const averageColorResult = await getColorForImageUrl(
                          getImageUrl(dimension.image.url, 'square'),
                        );
                        setColor(averageColorResult);
                      }
                      setCurrentVoteConfirmationItem(dimension);
                    }
                  },
                }))}
              />
            </Stack>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};
