import { nanoid } from 'nanoid';
import { getMiraConfig } from './mira';

export const getMiraUrl = (): string | undefined => {
  const config = getMiraConfig();

  if (!config) {
    return undefined;
  }

  const redirectParams = new URLSearchParams(document.location.search);
  redirectParams.set('auth', 'login');
  redirectParams.set('r', nanoid());

  const params = new URLSearchParams();
  params.set('cid', config.clientId);
  params.set(
    'callbackUrl',
    `${document.location.protocol}//${document.location.hostname}${
      document.location.pathname
    }${config.type === 'magellan' ? `?${redirectParams.toString()}` : ''}`,
  );
  if (window.cmp) {
    params.set('cmpUcId', window.cmp.getControllerId());
    params.set('cmpUcInstance', window.cmp.getSettingsId());
  }

  return `https://${config.endpoint}/sso/auth?${params.toString()}`;
};

export type MiraUserApiResult = {
  status: number;
  response: {
    sid: string;
    userId: string;
    data: {
      cmpUc: Record<string, string>;
      person: {
        name: string;
        firstName: string;
        lastName: string;
        birthdate: string;
        gender: string;
        email: string;
      };
      ageGroup: string;
      entitlements: string[];
      userSegments: string[];
    };
    miratoken: string;
    expiresAt: number;
  };
};

export type MiraUser = {
  name: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  gender: string;
  email: string;
};

export const getMiraUser = async (): Promise<MiraUser | undefined> => {
  const config = getMiraConfig();

  if (!config) {
    return undefined;
  }

  try {
    const response = await fetch(`https://${config.endpoint}/sso/user`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
    });
    const result = (await response.json()) as MiraUserApiResult;
    return result.response.data.person;
  } catch {
    return undefined;
  }
};

export const triggerAuth = (): void => {
  const miraUrl = getMiraUrl();
  if (miraUrl) {
    window.location.href = miraUrl;
  } else {
    console.error('Could not derive MIRA configuration');
  }
};
