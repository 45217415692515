import { nanoid } from 'nanoid';
import { log } from './logging';

type SomMarketingSlotName =
  | 'preroll'
  | 'sponsoropener'
  | 'presplit'
  | 'midroll'
  | 'postsplit'
  | 'sponsorcloser'
  | 'postroll'
  | 'pauseroll'
  | 'sponsor'
  | 'overlay';

type SomMarketingSlotConfig = {
  enabled?: boolean;
  count?: number;
};

type SomMarketingSlots = Partial<
  Record<SomMarketingSlotName, SomMarketingSlotConfig>
>;

type SomMarketingBlockName = 'pre' | 'mid' | 'post' | 'overlay' | 'pause';

type SomMarketingBlockConfig = {
  enabled: boolean;
  slots?: SomMarketingSlots;
};

type SomMarketingBlocks = Partial<
  Record<SomMarketingBlockName, SomMarketingBlockConfig>
>;

export type SomMarketing = {
  enabled?: boolean;
  config: {
    id: string;
  };
  taxonomy: {
    affiliate?: string;
    channels: string[];
    content: string;
  };
  device?: {
    mode: string;
  };
  display?: {
    enabled: boolean;
  };
  midrollOffsets?: number[];
  video: {
    longplay?: boolean;
    autoplay?: boolean;
    blocks: SomMarketingBlocks;
  };
};

export type InsertAdError = string | null;

export type InsertAdResult = {
  type: 'DISABLED' | 'DUPLICATE' | 'adRequest' | 'error' | 'adResponse';
  data: {
    slotName: string;
    message?: string;
    name?: string;
    options: {
      container: string | HTMLElement;
      custom?: unknown[];
    };
    adConfig?: {
      width: number;
      height: number;
      slot: string;
      adTrace: string;
      adName: string;
      adId?: string;
      productName: 'unknown';
      legacy: {
        width: number;
        height: number;
        slot: string;
        adTrace: string;
        adName: string;
        adId?: string;
      };
      adserverMacros: {
        site: string;
        unit: string;
        advertiser: string;
        order: string;
        lineitem: string;
        creative: string;
        width: string;
        height: string;
        cachebuster: string;
      };
    };
  };
};

const SOM_TAG_LOADER_URL = '//ad.71i.de/somtag/loader/loader.js';

export const init = (): void => {
  if (!globalThis.somtag) {
    globalThis.somtag = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cmd(...args: any[]) {
        const g = Array.from(args);
        g.push(
          document.currentScript ||
            document.scripts[document.scripts.length - 1],
        );
        (this.q = this.q || []).push(g);
      },
    };

    const script = document.createElement('script');
    script.src = SOM_TAG_LOADER_URL;
    document.head.appendChild(script);

    globalThis.somtag.cmd('init', {
      enabled: true,
      config: {
        id: 'pro7',
      },
      device: {
        mode: 'desktop',
      },
      taxonomy: {
        channels: ['dave'],
        content: 'content',
      },
      custom: [
        {
          key: 'dave',
          value: 1,
        },
      ],
      display: {
        slots: {
          rectangle1: {
            enabled: true,
          },
          custom1: {
            enabled: true,
          },
        },
      },
    });
  } else {
    log('Skipping SOM initialization');
  }
};

export const insertAd = (
  slotName: string,
  selectorOrElement: string | HTMLElement,
): void => {
  if (globalThis.somtag) {
    globalThis.somtag.cmd(
      'insertAd',
      slotName,
      {
        container: selectorOrElement,
      },
      (error: InsertAdError, result: InsertAdResult) => {
        log({
          error,
          result,
        });
      },
    );
  }
};

export const reloadDisplaySlots = (
  slotName: string,
  selectorOrElement: string | HTMLElement,
): void => {
  if (globalThis.somtag) {
    globalThis.somtag.cmd(
      'reloadDisplaySlots',
      [slotName],
      {
        container: selectorOrElement,
      },
      (error: InsertAdError, result: InsertAdResult) => {
        log({
          error,
          result,
        });
      },
    );
  }
};

export const getSlotNameForEnvironment = (slotName: string): string =>
  document.location.hostname === 'localhost' ? 'custom1' : slotName;

export const createFrameDocument = (): string => {
  const container = `ad${nanoid()}`;

  const init = {
    enabled: true,
    config: {
      id: 'dave-pro7',
    },
    device: {
      mode: 'desktop',
    },
    taxonomy: {
      channels: ['dave'],
      content: 'content',
    },
    display: {
      slots: {
        rectangle1: {
          enabled: true,
          products: {
            mediumRectangle: {
              enabled: true,
            },
          },
        },
      },
    },
  };

  const insertAd = {
    slot: 'rectangle1',
    container: `#${container}`,
  };

  const url = new URL(
    'https://ad.71i.de/somtag/core/release/v2/somtag/singletag/tag.js',
  );
  url.searchParams.set('init', JSON.stringify(init));
  url.searchParams.set('insertAd', JSON.stringify(insertAd));

  return `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            * {
              margin: 0;
              padding: 0;
              background: none;
              border: 0;
            }

            html,
            body {
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              font-family: sans-serif;
            }
          </style>
        </head>
        <body>
          <div id="${container}"></div>
          <script src="${url.toString()}"></script>
        </body>
      </html>
    `;
};
