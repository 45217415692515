import { FC } from 'react';

import { useConfig } from '../utils/config';
import { Button } from '../components/Button';
import { Headline } from '../components/Headline';
import { Copy } from '../components/Copy';
import { triggerAuth } from '../utils/auth';

import styles from './MonterosaTeaser.module.scss';

export const MonterosaTeaser: FC = () => {
  const { name, custom } = useConfig();

  return (
    <div className={styles['monterosa-teaser']}>
      {custom?.cover ? (
        <div className={styles['monterosa-teaser__cover']}>
          <img
            src={String(custom?.cover)}
            alt=""
            className={styles['monterosa-teaser__cover-image']}
          />
        </div>
      ) : null}
      {custom?.image ? (
        <div className={styles['monterosa-teaser__visual']}>
          <img
            src={String(custom?.background)}
            alt=""
            className={styles['monterosa-teaser__background']}
          />
          <img
            src={String(custom?.image)}
            alt=""
            className={styles['monterosa-teaser__image']}
          />
        </div>
      ) : null}
      <div className={styles['monterosa-teaser__content']}>
        <div className={styles['monterosa-teaser__title']}>
          <Headline level={4}>{custom?.headline}</Headline>
        </div>
        {custom?.subline ? (
          <div className={styles['monterosa-teaser__subline']}>
            <Copy size="large">{custom?.subline}</Copy>
          </div>
        ) : null}
        <div className={styles['monterosa-teaser__action']}>
          {name ? (
            <Button
              label={String(custom?.actionLoggedIn)}
              onClick={() => window.open(String(custom?.url))}
            />
          ) : (
            <Button
              label={String(custom?.actionLoggedOut)}
              onClick={() => triggerAuth()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
