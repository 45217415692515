import { FC } from 'react';

const DEFAULT_SIZE = 24;
const DEFAULT_COLOR = 'currentColor';
const DEFAULT_VIEW_BOX = '0 0 24 24';

export type IconProps = {
  size?: number;
  color?: string;
};

export const ArrowLeft: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M7.845 11h11.322a.833.833 0 0 1 0 1.667H7.845l3.578 3.577a.833.833 0 1 1-1.179 1.179l-5-5a.833.833 0 0 1 0-1.179l5-5a.833.833 0 1 1 1.179 1.179L7.845 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ArrowRight: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M16.155 11H4.833a.833.833 0 1 0 0 1.667h11.322l-3.578 3.577a.833.833 0 0 0 1.179 1.179l5-5a.833.833 0 0 0 0-1.179l-5-5a.833.833 0 1 0-1.179 1.179L16.155 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Bolt: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M6.484 24h1.972c.358 0 .701-.144.954-.397 1.26-1.266 5.523-5.503 12.466-11.946.891-.827.31-2.324-.906-2.324h-6.864L16.638 0h-1.99c-.348 0-.682.136-.93.379l-12.313 12c-.856.834-.265 2.288.931 2.288h6.68L6.484 24Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Bookmark: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M16.999 3h-10c-1.1 0-1.99.9-1.99 2L5 19.482a1 1 0 0 0 1.394.92L11.999 18l5.606 2.403a1 1 0 0 0 1.394-.92V5c0-1.1-.9-2-2-2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Checkmark: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M21.602 5.398c.53.53.53 1.39 0 1.92L10.08 18.841c-.204.204-.457.33-.721.376l-.16.02h-.16a1.352 1.352 0 0 1-.88-.396L2.398 13.08a1.358 1.358 0 0 1 1.92-1.92l4.8 4.8L19.682 5.398c.53-.53 1.39-.53 1.92 0Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronLeft: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M14.238 8.417a.83.83 0 1 0-1.18-1.17L9.244 11.06a.842.842 0 0 0 0 1.186l3.814 3.814a.832.832 0 1 0 1.18-1.171l-3.228-3.236 3.228-3.236Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronRight: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M9.245 8.417a.83.83 0 1 1 1.18-1.17l3.813 3.813a.842.842 0 0 1 0 1.186l-3.813 3.814a.832.832 0 1 1-1.18-1.171l3.228-3.236-3.228-3.236Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Cross: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Expand: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81171 18.3529H10.1765C10.6313 18.3529 11 18.7216 11 19.1765C11 19.6313 10.6313 20 10.1765 20H4.82353C4.36871 20 4 19.6313 4 19.1765V13.8235C4 13.3687 4.36871 13 4.82353 13C5.27835 13 5.64706 13.3687 5.64706 13.8235V17.1883L9.18238 13.653C9.50399 13.3314 10.0254 13.3314 10.347 13.653C10.6686 13.9746 10.6686 14.496 10.347 14.8176L6.81171 18.3529ZM17.1883 5.64706H13.8235C13.3687 5.64706 13 5.27835 13 4.82353C13 4.36871 13.3687 4 13.8235 4H19.1765C19.6313 4 20 4.36871 20 4.82353V10.1765C20 10.6313 19.6313 11 19.1765 11C18.7216 11 18.3529 10.6313 18.3529 10.1765V6.81171L14.8176 10.347C14.496 10.6686 13.9746 10.6686 13.653 10.347C13.3314 10.0254 13.3314 9.50399 13.653 9.18238L17.1883 5.64706Z"
        fill={color}
      />
    </svg>
  );
};

export const Flip: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M11.999 6C6.47902 6 1.99902 8.24 1.99902 11C1.99902 13.24 4.93902 15.13 8.99902 15.77V19L12.999 15L8.99902 11V13.73C5.84902 13.17 3.99902 11.83 3.99902 11C3.99902 9.94 7.03902 8 11.999 8C16.959 8 19.999 9.94 19.999 11C19.999 11.73 18.539 12.89 15.999 13.53V15.58C19.529 14.81 21.999 13.05 21.999 11C21.999 8.24 17.519 6 11.999 6Z"
        fill={color}
      />
    </svg>
  );
};

export const Heart: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="m12 21.35-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const HeartEyes: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM9.603 6.85C8.811 5.476 7.53 5.17 6.507 5.761c-.606.35-.993.92-1.029 1.639-.64-.329-1.328-.279-1.934.071-1.022.59-1.399 1.853-.606 3.226.792 1.372 5.936 2.585 5.936 2.585s1.522-5.062.73-6.434Zm10.752.622c1.023.59 1.4 1.853.607 3.226-.793 1.372-5.936 2.585-5.936 2.585s-1.522-5.061-.73-6.434c.792-1.372 2.074-1.678 3.097-1.087.605.35.993.92 1.028 1.64.64-.33 1.329-.28 1.934.07ZM12 20.5c4.714 0 6-4.5 6-5.5 0 0-2.686 1-6 1s-6-1-6-1c0 1 1.286 5.5 6 5.5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Like: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M3 19.833c0 .46.373.834.833.834H5.5c.46 0 .833-.373.833-.834V11.5a.833.833 0 0 0-.833-.833H3.833A.833.833 0 0 0 3 11.5v8.333ZM21.333 11.5c0-.917-.75-1.667-1.666-1.667h-5.259l.792-3.808.025-.267c0-.341-.142-.658-.367-.883L13.975 4 8.492 9.492A1.63 1.63 0 0 0 8 10.667V19c0 .917.75 1.667 1.667 1.667h7.5c.691 0 1.283-.417 1.533-1.017l2.517-5.875c.075-.192.116-.392.116-.608V11.5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Pause: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M6 19h4V5H6v14Zm8-14v14h4V5h-4Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Play: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M7.524 5.15A1 1 0 0 0 6 6.002v12.42a1 1 0 0 0 1.524.852l10.092-6.21a1 1 0 0 0 0-1.704L7.524 5.15Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Send: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M3.008 20.691a1 1 0 0 0 1.466.884L20.32 13.23a1 1 0 0 0 0-1.77L4.474 3.118A1 1 0 0 0 3.008 4L3 10.346l11 2-11 2 .008 6.345Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Trophy: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M5 0a1 1 0 0 0-1 1v1H1a1 1 0 0 0-1 1v4a5 5 0 0 0 5.07 5A8.016 8.016 0 0 0 10 15.747V20H5.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5H14v-4.252A8.016 8.016 0 0 0 18.93 12H19a5 5 0 0 0 5-5V3a1 1 0 0 0-1-1h-3V1a1 1 0 0 0-1-1H5ZM2 4h2v4c0 .654.079 1.29.227 1.9A3.001 3.001 0 0 1 2 7V4Zm17.773 5.9A8.023 8.023 0 0 0 20 8V4h2v3c0 1.39-.945 2.558-2.227 2.9ZM17 5.911C17 4.059 15.756 3 14.222 3c-.908 0-1.715.352-2.222 1.059C11.493 3.352 10.686 3 9.778 3 8.244 3 7 4.059 7 5.912S12 12 12 12s5-4.235 5-6.088Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Videos: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12V20C22 21.1 21.1 22 20 22H4C2.9 22 2 21.1 2 20V12C2 10.9 2.9 10 4 10H20C21.1 10 22 10.9 22 12ZM16 16L10 12.73V19.26L16 16ZM20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7ZM18 3C18 2.44772 17.5523 2 17 2H7C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4H17C17.5523 4 18 3.55228 18 3Z"
        fill={color}
      />
    </svg>
  );
};

export const Icon = {
  ArrowLeft,
  ArrowRight,
  Bolt,
  Bookmark,
  Checkmark,
  Cross,
  Expand,
  Flip,
  Heart,
  HeartEyes,
  Like,
  Pause,
  Play,
  Send,
  Trophy,
  Videos,
} as const;

export type IconName = keyof typeof Icon;

export const isIconName = (input: unknown): input is IconName => {
  return (
    typeof input === 'string' &&
    Object.prototype.hasOwnProperty.call(Icon, input)
  );
};

export const getIcon = (icon: IconName): FC<IconProps> => {
  return Icon[icon];
};
