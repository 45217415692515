"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THEMES = void 0;
exports.THEMES = [
    'default-dark',
    'tms-dark',
    'zol-dark',
    'ran-dark',
    'sixx-dark',
    'sixx-light',
    'reimanns-dark',
    'tvk-dark',
    'gntm-dark',
    'quizfuerdich-dark',
    'luckystars-dark',
    'b2b-dark',
    'zappn-dark',
];
