import { createContext, useContext } from 'react';
import {
  Voting,
  VotingStepTypeKey,
  VotingStepType,
  ResultsStep,
  ThankYouStep,
  VotingStep,
  isVotingStep,
  isResultsStep,
  isThankYouStep,
  isGroupStep,
  isIntroStep,
  IntroStep,
  GroupStep,
} from '@data/eve-sdk-web';

export const isVoteable = (voting: Voting): boolean =>
  voting.state.voting === 'active';

export const isRevealed = (voting: Voting): boolean =>
  voting.state.results === 'show';

export const hasConfirmation = (voting: Voting): boolean =>
  voting.steps.some((step) => isThankYouStep(step));

export const getStep = (
  voting: Voting,
  step: VotingStepTypeKey,
): VotingStepType | undefined =>
  voting.steps.find((item) => item.type === step);

export const getVotingStep = (voting: Voting): VotingStep | undefined =>
  voting.steps.find((step) => isVotingStep(step)) as VotingStep | undefined;

export const getIntroStep = (voting: Voting): IntroStep | undefined =>
  voting.steps.find((step) => isIntroStep(step)) as IntroStep | undefined;

export const getThankYouStep = (voting: Voting): ThankYouStep | undefined =>
  voting.steps.find((step) => isThankYouStep(step)) as ThankYouStep | undefined;

export const getResultsStep = (voting: Voting): ResultsStep | undefined =>
  voting.steps.find((step) => isResultsStep(step)) as ResultsStep | undefined;

export const getGroupSteps = (voting: Voting): GroupStep[] =>
  voting.steps.filter((step) => isGroupStep(step)) as GroupStep[];

export const getActiveVotings = (votings: Voting[]): Voting[] => {
  return votings.filter((voting) => isVoteable(voting) || isRevealed(voting));
};

export const VotingsContext = createContext<Voting[]>([]);

export const useVotings = (): Voting[] => useContext(VotingsContext);
