import get from 'lodash/get';

export const SORT_ORDERS = ['asc', 'desc'] as const;

export type SortOrder = typeof SORT_ORDERS[number];

export const sortBy = <T>(list: T[], key: string, order: SortOrder): T[] => {
  const sorted = [...list].sort((a, b) =>
    String(get(a, key)).localeCompare(String(get(b, key)), undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  );
  if (order === 'desc') {
    return sorted.reverse();
  }
  return sorted;
};
