"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isGroupStep = exports.isResultsStep = exports.isThankYouStep = exports.isIntroStep = exports.isVotingStep = void 0;
var isVotingStep = function (step) {
    return step.type === 'Voting';
};
exports.isVotingStep = isVotingStep;
var isIntroStep = function (step) {
    return step.type === 'Intro';
};
exports.isIntroStep = isIntroStep;
var isThankYouStep = function (step) {
    return step.type === 'ThankYou';
};
exports.isThankYouStep = isThankYouStep;
var isResultsStep = function (step) {
    return step.type === 'Results';
};
exports.isResultsStep = isResultsStep;
var isGroupStep = function (step) {
    return step.type === 'Group';
};
exports.isGroupStep = isGroupStep;
