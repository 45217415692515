import get from 'lodash/get';
import { isEnabled } from './toggles';

export type AdColors = {
  background: string;
  button: {
    background: string;
    color: string;
  };
};

export type AdTracking = {
  name: string;
};

export class ResultsAd {
  public type: 'ResultsAd' = 'ResultsAd';
  public id: string;
  public title: string;
  public coverUrl: string;
  public logoUrl: string;
  public backgroundColor: string;
  public textColor: string;
  public tracking: AdTracking;
  public onClick: () => void;

  constructor({
    id,
    title,
    coverUrl,
    logoUrl,
    backgroundColor,
    textColor,
    tracking,
    onClick,
  }: {
    id: string;
    title: string;
    coverUrl: string;
    logoUrl: string;
    backgroundColor: string;
    textColor: string;
    tracking: AdTracking;
    onClick: () => void;
  }) {
    this.id = id;
    this.title = title;
    this.coverUrl = coverUrl;
    this.logoUrl = logoUrl;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.tracking = tracking;
    this.onClick = onClick;
  }
}

export class ModalAd {
  public type: 'ModalAd' = 'ModalAd';
  public id: string;
  public title: string;
  public coverUrl: string;
  public tracking: AdTracking;
  public onClick: () => void;

  constructor({
    id,
    title,
    coverUrl,
    tracking,
    onClick,
  }: {
    id: string;
    title: string;
    coverUrl: string;
    tracking: AdTracking;
    onClick: () => void;
  }) {
    this.id = id;
    this.title = title;
    this.coverUrl = coverUrl;
    this.tracking = tracking;
    this.onClick = onClick;
  }
}

export const isModalAd = (input: unknown): input is ModalAd =>
  typeof input === 'object' &&
  input !== null &&
  Object.prototype.hasOwnProperty.call(input, 'type') &&
  get(input, 'type') === 'ModalAd';

export class NativeAdStep {
  public type: 'NativeAdStep' = 'NativeAdStep';
  public id: string;
  public title: string;
  public subTitle: string;
  public ctaTitle: string;
  public description?: string;
  public imageUrl: string;
  public colors: AdColors;
  public tracking: AdTracking;
  public onClick: () => void;

  constructor({
    id,
    title,
    subTitle,
    ctaTitle,
    description,
    imageUrl,
    colors,
    tracking,
    onClick,
  }: {
    id: string;
    title: string;
    subTitle: string;
    ctaTitle: string;
    description?: string;
    imageUrl: string;
    colors: AdColors;
    tracking: AdTracking;
    onClick: () => void;
  }) {
    this.id = id;
    this.title = title;
    this.subTitle = subTitle;
    this.ctaTitle = ctaTitle;
    this.description = description;
    this.imageUrl = imageUrl;
    this.colors = colors;
    this.tracking = tracking;
    this.onClick = onClick;
  }
}

export class SomAdStep {
  public type: 'SomAdStep' = 'SomAdStep';
  public id: string;
  public title: string;
  public subTitle: string;
  public tracking: AdTracking;
  public onClick: () => void;

  constructor({
    id,
    title,
    subTitle,
    tracking,
    onClick,
  }: {
    id: string;
    title: string;
    subTitle: string;
    tracking: AdTracking;
    onClick: () => void;
  }) {
    this.id = id;
    this.title = title;
    this.subTitle = subTitle;
    this.tracking = tracking;
    this.onClick = onClick;
  }
}

const SOM_SLOTS: number[] = isEnabled('battleLaneAds')
  ? [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
  : [];
const SOM_WORDINGS = [
  {
    title: 'Werbung muss halt sein.',
    subTitle: 'Weißte ja…',
  },
  {
    title: 'Sorry, ganz kurz nur!',
    subTitle: 'Bisschen Werbung…',
  },
  {
    title: 'Und nur für Dich!',
    subTitle: 'Eine sehr schöne Werbung…',
  },
  {
    title: 'Reklame, Werbung, …',
    subTitle: 'Egal, wie man es nennt.',
  },
];

export const injectAds = <StepType>(
  steps: StepType[],
  context: string,
): (NativeAdStep | SomAdStep | StepType)[] => {
  if (context === 'tvk-s10-DISABLED') {
    const patched: (NativeAdStep | SomAdStep | StepType)[] = [...steps];
    SOM_SLOTS.forEach((slotIndex, index) => {
      const wording = SOM_WORDINGS[index % SOM_WORDINGS.length];
      if (patched.length - 1 >= slotIndex) {
        patched.splice(
          slotIndex - 1,
          0,
          new SomAdStep({
            id: `AD_SOM${slotIndex}`,
            ...wording,
            tracking: {
              name: `SOM ${slotIndex}`,
            },
            onClick: () => {
              /* NOOP */
            },
          }),
        );
      }
    });

    return patched;
  }

  return steps;
};

export const resultsAds: ResultsAd[] = [];

export const modalsAds: ModalAd[] = [];
