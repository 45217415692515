import { FC } from 'react';
import { createFrameDocument } from '../utils/som';

import styles from './SomFrame.module.scss';

const html = createFrameDocument();

export const SomFrame: FC = () => {
  return (
    <iframe className={styles['som-frame']} srcDoc={html} title="Ad"></iframe>
  );
};
