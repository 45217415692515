import { FC, Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  addVote,
  removeVote,
  Voting,
  VotingDimension,
} from '@data/eve-sdk-web';
import { AnimatePresence, motion } from 'framer-motion';

import { Headline } from '../components/Headline';
import { Button } from './Button';
import { getColorForImageUrl, getImageUrl } from '../utils/images';
import { getResultsStep, getVotingStep } from '../utils/votings';
import { useNotification } from './Notifications';
import { formatPercentage } from '../utils/ui';
import { useConfig } from '../utils/config';
import { isEnabled } from '../utils/toggles';
import { Modal } from './Modal';
import { getItem, removeItem, setItem } from '../utils/storage';
import { Card } from './Card';
import { Copy } from './Copy';
import { Stack } from './Stack';
import { triggerAuth } from '../utils/auth';
import { emit, on } from '../utils/bus';
import { trackEvent } from '../utils/tracking';
import { useLane } from '../steps/Lane';
import { isCorrectDimension, supportsCorrectnessCheck } from '../utils/hacks';
import { Checkmark, Cross } from './Icon';
import { isLargeDown } from '../utils/breakpoints';

import styles from './LaneItem.module.scss';

export type LaneItemProps = {
  index?: number;
  voting: Voting;
  nextVoting?: Voting;
};

export const LaneItem: FC<LaneItemProps> = ({ index, voting, nextVoting }) => {
  const { count: laneCount } = useLane();
  const [showAuthWall, setShowAuthWall] = useState<boolean>(false);
  const {
    context,
    name,
    miraToken,
    notification: notificationText,
    group,
    custom,
  } = useConfig();
  const notification = useNotification();
  const votingStep = getVotingStep(voting);
  const resultsStep = getResultsStep(voting);
  const cachedVote = getItem<string>(`vote:${voting.flowId}`);
  const userVote = ((votingStep ?? resultsStep)?.dimensions ?? []).find(
    (dimension) => dimension.userVote,
  );
  const [selectedItem, setSelectedItem] = useState<VotingDimension | undefined>(
    cachedVote
      ? ((votingStep ?? resultsStep)?.dimensions ?? []).find(
          (dimension) => dimension.id === cachedVote,
        ) ?? userVote
      : userVote,
  );
  const revealResults = isEnabled('laneRevealResults');
  const isFlippable = Boolean(
    revealResults ? votingStep || resultsStep : votingStep,
  );
  const hasChangedVote = Boolean(
    selectedItem && userVote?.id && selectedItem.id !== userVote.id,
  );
  const triggerNotification = () => {
    if (!custom?.laneDisableNotification) {
      notification({
        id: String(Math.random()),
        title: hasChangedVote
          ? name
            ? `Du hast deine Stimme geändert, ${name}.`
            : 'Du hast deine Stimme geändert.'
          : name
          ? `Danke für deine Stimme, ${name}!`
          : 'Danke für deine Stimme!',
        text: notificationText,
      });
    }
  };

  const handleCachedVote = async () => {
    if (cachedVote && votingStep?.bucketId) {
      addVote(context, votingStep.bucketId, cachedVote, 1);
      removeItem(`vote:${voting.flowId}`);
      triggerNotification();
    }
  };

  useEffect(() => {
    setSelectedItem((currentSelectedItem) => {
      return currentSelectedItem
        ? currentSelectedItem
        : cachedVote
        ? ((votingStep ?? resultsStep)?.dimensions ?? []).find(
            (dimension) => dimension.id === cachedVote,
          ) ?? userVote
        : userVote;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userVote]);

  useEffect(() => {
    if (miraToken && cachedVote) {
      window.setTimeout(() => {
        handleCachedVote();
      }, 3 * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedVote]);

  useEffect(() => {
    on(`lane-show-item:${voting.flowId}`, () => {
      emit('lane:scroll', {
        votingId: voting.flowId,
      });
      emit(`card:flip:${voting.flowId}`);
    });
  }, [voting.flowId]);

  return (
    <>
      <div
        className={classNames(
          styles['lane-item'],
          styles[`lane-item--${voting?.type}`],
          {
            [styles['lane-item--voted']]: !!userVote,
            [styles['lane-item--flippable']]: isFlippable,
            [styles['lane-item--inactive']]: !isFlippable,
            [styles['lane-item--plain']]: !voting.image,
            [styles['lane-item--visual']]: !!voting.image,
          },
        )}>
        <Card
          id={voting.flowId}
          flippable={isFlippable}
          passive={!!userVote}
          coverImage={
            voting.image ? getImageUrl(voting.image.url, 'original') : undefined
          }
          topline={index ? `Frage #${index}` : undefined}
          label={voting.title}
          badge={
            isFlippable
              ? !votingStep && resultsStep
                ? custom?.laneBadgeReveal
                  ? String(custom?.laneBadgeReveal)
                  : 'Alle Ergebnisse anzeigen'
                : userVote
                ? custom?.laneBadgeChange
                  ? String(custom?.laneBadgeChange)
                  : 'Antwort ändern'
                : custom?.laneBadge
                ? String(custom?.laneBadge)
                : 'Jetzt abstimmen'
              : custom?.laneBadgeStopped
              ? String(custom?.laneBadgeStopped)
              : 'Abstimmung vorbei'
          }
          cover={({ isFlipped }) => (
            <>
              {votingStep && userVote ? (
                <AnimatePresence>
                  <motion.div
                    key="user-vote"
                    animate={!isFlipped ? 'visible' : 'hidden'}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 15, opacity: 0 },
                      initial: { y: 15, opacity: 0 },
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 0.5,
                    }}
                    className={styles['lane-item__vote']}>
                    <div className={styles['lane-item__vote-label']}>
                      Deine Antwort
                    </div>
                    <div className={styles['lane-item__vote-value']}>
                      <Headline level={6}>{userVote.title}</Headline>
                      {supportsCorrectnessCheck(votingStep.bucketId) ? (
                        <div
                          className={classNames(
                            styles['lane-item__vote-status'],
                            {
                              [styles['lane-item__vote-status--valid']]:
                                isCorrectDimension(
                                  votingStep.bucketId,
                                  userVote.id,
                                ),
                            },
                          )}>
                          {isCorrectDimension(
                            votingStep.bucketId,
                            userVote.id,
                          ) ? (
                            <Checkmark size={16} />
                          ) : (
                            <Cross size={16} />
                          )}
                        </div>
                      ) : null}
                    </div>
                  </motion.div>
                </AnimatePresence>
              ) : null}
              {resultsStep ? (
                <AnimatePresence>
                  <motion.div
                    key="community-vote"
                    className={classNames(
                      styles['lane-item__vote'],
                      styles['lane-item__vote--highlighted'],
                    )}>
                    <div className={styles['lane-item__vote-label']}>
                      {resultsStep.view === 'numbersList'
                        ? `Die Community sagt ${resultsStep.dimensions[0].result?.number}×`
                        : resultsStep.view === 'percentageList' ||
                          resultsStep.view === 'percentageAndNumber'
                        ? `Die Community sagt zu ${formatPercentage(
                            resultsStep.dimensions[0].result?.percentage ?? 0,
                          )}%`
                        : 'Die Community sagt'}
                    </div>
                    <Headline level={6}>
                      {resultsStep.dimensions[0].title}
                    </Headline>
                    {resultsStep.view === 'percentageList' ||
                    resultsStep.view === 'percentageAndNumber' ? (
                      <div className={styles['lane-item__vote-bar']}>
                        <div
                          className={styles['lane-item__vote-progress']}
                          style={{
                            width: `${
                              resultsStep.dimensions[0].result?.percentage ?? 0
                            }%`,
                          }}
                        />
                      </div>
                    ) : null}
                  </motion.div>
                </AnimatePresence>
              ) : null}
            </>
          )}
          details={({ setColor, setIsFlipped }) => (
            <div className={styles['lane-item__options']}>
              {(votingStep?.dimensions ?? []).map(
                (dimension, dimensionIndex) => (
                  <Fragment key={dimension.id}>
                    {dimensionIndex > 0 && voting?.type === 'battles' ? (
                      <div className={styles['lane-item__divider']}>vs</div>
                    ) : null}
                    <div
                      className={classNames(styles['lane-item__option'], {
                        [styles['lane-item__option--active']]:
                          selectedItem?.id === dimension.id,
                      })}
                      onClick={async () => {
                        if (selectedItem && selectedItem.id === dimension.id) {
                          try {
                            if (voting.image) {
                              const averageColorResult =
                                await getColorForImageUrl(
                                  getImageUrl(voting.image.url, 'splash'),
                                );
                              setColor(averageColorResult);
                            }
                          } catch {
                            /* NOOP */
                          }
                          if (miraToken) {
                            if (votingStep?.bucketId) {
                              await removeVote(
                                context,
                                votingStep.bucketId,
                                dimension.id,
                                1,
                              );
                            }
                            if (isLargeDown()) {
                              if (nextVoting) {
                                emit(`lane-show-item:${nextVoting.flowId}`);
                              }
                            } else {
                              triggerNotification();
                            }
                            window.setTimeout(() => {
                              setIsFlipped(false);
                            }, 1500);
                          }
                          setSelectedItem(undefined);
                        } else {
                          try {
                            if (dimension.image?.url) {
                              const averageColorResult =
                                await getColorForImageUrl(
                                  getImageUrl(dimension.image.url, 'square'),
                                );
                              setColor(averageColorResult);
                            }
                          } catch {
                            /* NOOP */
                          }
                          if (miraToken) {
                            if (votingStep?.bucketId) {
                              if (userVote?.id) {
                                await removeVote(
                                  context,
                                  votingStep.bucketId,
                                  userVote.id,
                                  1,
                                );
                              }
                              await addVote(
                                context,
                                votingStep.bucketId,
                                dimension.id,
                                1,
                              );
                            }
                            removeItem(`vote:${voting.flowId}`);
                            trackEvent('Voting Click', {
                              group: dimension.title.trim(),
                              label: `${voting.title.trim()} - ${index}/${laneCount}`,
                            });
                            if (isLargeDown()) {
                              if (nextVoting) {
                                emit(`lane-show-item:${nextVoting.flowId}`);
                              }
                            } else {
                              triggerNotification();
                            }
                            window.setTimeout(() => {
                              setIsFlipped(false);
                            }, 1500);
                          } else {
                            setItem(`vote:${voting.flowId}`, dimension?.id);
                            setShowAuthWall(true);
                            trackEvent('Auth Wall Show', {
                              group: dimension.title.trim(),
                              label: `${voting.title.trim()} - ${index}/${laneCount}`,
                            });
                          }
                          setSelectedItem(dimension);
                        }
                      }}>
                      <div className={styles['lane-item__option-content']}>
                        <div className={styles['lane-item__option-label']}>
                          {dimension.title}
                        </div>
                      </div>
                      {dimension.image ? (
                        <div className={styles['lane-item__option-visual']}>
                          <img
                            src={
                              dimension.image?.url
                                ? getImageUrl(dimension.image.url, 'square')
                                : undefined
                            }
                            alt={dimension.title}
                            className={styles['lane-item__option-image']}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Fragment>
                ),
              )}
            </div>
          )}
          onFlip={(isFlipped) => {
            if (isFlipped) {
              trackEvent('Voting Teaser Click', {
                group: String(group),
                label: `${voting.title.trim()} - ${index}/${laneCount}`,
              });
            }
          }}
        />
      </div>
      <Modal
        id="auth"
        variant="small"
        align="center"
        open={showAuthWall}
        onClose={() => setShowAuthWall(false)}>
        <Stack>
          <svg
            viewBox="0 0 52 64"
            className={styles['lane-item__auth-wall-illustration']}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.5 6.74994C19.0442 6.74994 13 12.7941 13 20.2499C13 27.7058 19.0442 33.7499 26.5 33.7499C33.9558 33.7499 40 27.7058 40 20.2499C40 12.7941 33.9558 6.74994 26.5 6.74994ZM26.5 9.74994C32.299 9.74994 37 14.451 37 20.2499C37 26.0489 32.299 30.7499 26.5 30.7499C20.701 30.7499 16 26.0489 16 20.2499C16 14.451 20.701 9.74994 26.5 9.74994Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.1466 38.2499C42.5444 38.2499 50.3855 45.7558 51.4906 55.5823C51.5832 56.4055 50.9909 57.148 50.1677 57.2405C49.3444 57.3331 48.602 56.7408 48.5094 55.9176C47.5833 47.6826 41.1536 41.4258 33.4719 41.2536L33.1466 41.2499H18.8508C11.0334 41.2499 4.43862 47.555 3.49042 55.8962C3.39684 56.7193 2.65371 57.3107 1.83059 57.2171C1.00746 57.1236 0.416042 56.3804 0.509614 55.5573C1.61226 45.8576 9.27831 38.4265 18.5201 38.253L18.8508 38.2499H33.1466Z"
              fill="currentColor"
            />
          </svg>
          <Headline level={5} align="center">
            Melde dich an, um teilzunehmen
          </Headline>
          <Copy size="medium" align="center">
            Damit garantieren wir, dass alles fair abläuft.
          </Copy>
          <Stack align="center" gap="xs">
            <Button
              label="Jetzt anmelden"
              onClick={async () => {
                triggerAuth();
              }}
            />
            <Button
              variant="subtle"
              label="Nicht mitmachen"
              onClick={async () => {
                setShowAuthWall(false);
                setSelectedItem(undefined);
              }}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
