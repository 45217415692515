import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  setUser,
  setup,
  subscribe,
  Voting as VotingType,
  User,
} from '@data/eve-sdk-web';
import ReactCanvasConfetti from 'react-canvas-confetti';
import classNames from 'classnames';
import { Theme } from '@data/eve-sdk-web-ui-types';

import { useConfig } from './utils/config';
import { getActiveVotings, VotingsContext } from './utils/votings';
import { Step, StepContext } from './utils/step';
import { ColorContext } from './utils/colors';
import { setInstance as setConfettiInstance } from './utils/confetti';
import { FloatingBar } from './steps/FloatingBar';
import { Teaser } from './steps/Teaser';
import { Voting } from './steps/Voting';
import { Result } from './steps/Result';
import { Lane } from './steps/Lane';
import { BattleLane } from './steps/BattleLane';
import { Battle } from './steps/Battle';
import { Confirmation } from './steps/Confirmation';
import { MonterosaTeaser } from './steps/MonterosaTeaser';
import { Notifications } from './components/Notifications';
import { ThemeProvider } from './utils/themes';
import { getDesignThemeForContext } from './utils/ui';

import styles from './App.module.scss';

export const App: FC = () => {
  const {
    env,
    name,
    miraToken,
    context,
    variant,
    theme: passedTheme,
  } = useConfig();
  const ref = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState<Theme>(
    passedTheme ?? getDesignThemeForContext(context),
  );
  const [step, setStep] = useState<Step>('Splash');
  const [votings, setVotings] = useState<VotingType[]>([]);
  const [color, setColor] = useState<string | undefined>();
  const activeVotings = useMemo(() => getActiveVotings(votings), [votings]);
  const availableSteps: Step[] = useMemo(() => {
    const steps: Step[] = variant !== 'auto' ? ['Splash'] : [];
    steps.push(...(activeVotings[0]?.steps ?? []).map((item) => item.type));
    return steps;
  }, [activeVotings, variant]);
  const currentIndex = useMemo(
    () => availableSteps.findIndex((item) => item === step),
    [step, availableSteps],
  );
  const previousStep = useMemo(
    () =>
      currentIndex !== -1
        ? availableSteps[Math.max(currentIndex - 1, 0)] ?? 'Splash'
        : 'Splash',
    [currentIndex, availableSteps],
  );
  const nextStep = useMemo(
    () =>
      currentIndex !== -1
        ? availableSteps[Math.min(currentIndex + 1, availableSteps.length)] ??
          'Splash'
        : 'Splash',
    [currentIndex, availableSteps],
  );
  const user: User = useMemo(
    () => ({
      username: name || 'Guest',
      miraToken,
    }),
    [name, miraToken],
  );

  const init = useCallback(async () => {
    if (context) {
      console.log('[EVE] Setup', env, user);
      await setup(env, user);
      subscribe(context, (votings) => {
        setVotings(votings);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, env]);

  useEffect(() => {
    console.log('[EVE] Set user', user);
    if (user.username && (user.miraToken || user.idToken)) {
      setUser(user);
    }
  }, [user]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div
      ref={ref}
      className={classNames(styles.app, styles[`app--${variant}`])}
      style={{
        '--color': color,
      }}>
      <ThemeProvider theme={theme} setTheme={(newTheme) => setTheme(newTheme)}>
        <ColorContext.Provider
          value={{
            color,
            setColor,
          }}>
          {variant === 'monterosa-teaser' ? (
            <MonterosaTeaser />
          ) : (
            <VotingsContext.Provider value={votings}>
              <StepContext.Provider
                value={{
                  steps: availableSteps,
                  step,
                  setStep,
                  previousStep,
                  nextStep,
                  goToStart: () => setStep(availableSteps[0]),
                  goToPreviousStep: () => setStep(previousStep),
                  goToNextStep: () => setStep(nextStep),
                }}>
                {activeVotings.length ? (
                  variant === 'lane' ||
                  variant === 'battle-lane' ||
                  variant === 'battle' ? (
                    <>
                      {activeVotings.some(
                        (voting) => voting.type === 'videobattles',
                      ) ? (
                        <BattleLane
                          votings={activeVotings.filter(
                            (voting) => voting.type === 'videobattles',
                          )}
                        />
                      ) : activeVotings.some(
                          (voting) => voting.type === 'battles',
                        ) ? (
                        <Battle
                          votings={activeVotings.filter(
                            (voting) => voting.type === 'battles',
                          )}
                        />
                      ) : (
                        <Lane votings={activeVotings} />
                      )}
                      <Notifications />
                    </>
                  ) : (
                    <>
                      {variant === 'floating' ? (
                        <FloatingBar voting={activeVotings[0]} />
                      ) : variant === 'inline' || variant === 'teaser' ? (
                        <Teaser voting={activeVotings[0]} />
                      ) : null}
                      <Voting voting={activeVotings[0]} />
                      <Result voting={activeVotings[0]} />
                      <Confirmation voting={activeVotings[0]} />
                      <ReactCanvasConfetti
                        refConfetti={setConfettiInstance}
                        className={styles.app__confetti}
                      />
                    </>
                  )
                ) : null}
              </StepContext.Provider>
            </VotingsContext.Provider>
          )}
        </ColorContext.Provider>
        <div id="eve-portal" />
      </ThemeProvider>
    </div>
  );
};
