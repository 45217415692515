import { FC } from 'react';
import { Headline } from './Headline';
import { Stack } from './Stack';

import styles from './ActionTeaser.module.scss';

export type ActionTeaserProps = {
  image: string;
  title: string;
  text?: string;
  actionLabel: string;
  onClick: () => void;
  onMouseEnter?: () => void;
};

export const ActionTeaser: FC<ActionTeaserProps> = ({
  image,
  title,
  text,
  actionLabel,
  onClick,
  onMouseEnter,
}) => {
  return (
    <div
      className={styles['action-teaser']}
      onClick={() => onClick()}
      onMouseEnter={() => onMouseEnter?.()}>
      <div className={styles['action-teaser__visual']}>
        <img
          src={image}
          alt={title}
          className={styles['action-teaser__image']}
        />
      </div>
      <Stack gap="xs">
        <div className={styles['action-teaser__title']}>
          <Headline level={5}>{title}</Headline>
        </div>
        {text ? (
          <div className={styles['action-teaser__text']}>{text}</div>
        ) : null}
        <div className={styles['action-teaser__action']}>{actionLabel}</div>
      </Stack>
    </div>
  );
};
