import { getItem } from '../utils/storage';

export const toggles = {
  logging: false,
  laneRevealResults: false,
  battleLaneAds: false,
  battleLaneAutoplay: true,
} as const;

export type Toggle = keyof typeof toggles;

const getToggleStatesFromEnvironment = (): Partial<Record<Toggle, boolean>> => {
  const environmentStates = (process.env.REACT_APP_TOGGLES ?? '').split(
    /\s*,\s*/,
  );
  return Object.keys(toggles).reduce((acc, key) => {
    if (environmentStates.includes(key)) {
      return {
        ...acc,
        [key]: true,
      };
    }
    if (environmentStates.includes(`-${key}`)) {
      return {
        ...acc,
        [key]: false,
      };
    }
    return acc;
  }, {});
};

const getToggleStatesFromLocalStorage = (): Partial<
  Record<Toggle, boolean>
> => {
  let localStorageStates: Record<string, unknown> = {};
  try {
    localStorageStates = JSON.parse(getItem('toggles') ?? '{}');
  } catch {
    /* NOOP */
  }

  return Object.keys(toggles).reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(localStorageStates, key)) {
      return {
        ...acc,
        [key]: Boolean(localStorageStates[key]),
      };
    }
    return acc;
  }, {});
};

export const getToggleStates = (): Record<Toggle, boolean> => {
  const state = {
    ...toggles,
    ...getToggleStatesFromEnvironment(),
    ...getToggleStatesFromLocalStorage(),
  };
  return state;
};

export const isEnabled = (toggle: Toggle): boolean => {
  const state = getToggleStates();
  return state[toggle];
};
