import { Environment } from '@data/eve-sdk-web';
import { Config, Theme } from '@data/eve-sdk-web-ui-types';
import { Environment as ThemeEnvironment } from './themes';

const CONTEXTLESS_VARIANTS: Config['variant'][] = ['monterosa-teaser'];

export const getDesignSystemEnvironment = (
  env: Environment,
): ThemeEnvironment => {
  switch (env) {
    case 'int':
    case 'preprod':
      return ThemeEnvironment.INT;
    default:
      return ThemeEnvironment.PROD;
  }
};

export const getDesignThemeForContext = (context: string): Theme => {
  switch (context) {
    case 'zo-live':
      return 'zol-dark';
    case 'tms-s5':
      return 'tms-dark';
    case 'tmd-s1':
      return 'tms-dark';
    case 'diereimanns':
      return 'reimanns-dark';
    default:
      return 'default-dark';
  }
};

export const formatPercentage = (percentage: number): string =>
  `${percentage.toFixed(0)}%`;

export const isContextlessVariant = (variant: Config['variant']): boolean =>
  CONTEXTLESS_VARIANTS.includes(variant);

export const interpolate = (
  content: string,
  values: Record<string, string | number>,
): string => {
  return content.replace(/\{\{\s*([\w]+)\s*\}\}/g, (_, key) =>
    key in values ? String(values[key]) : key,
  );
};
