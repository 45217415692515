import { Environment } from '@data/eve-sdk-web';
import FastAverageColor from 'fast-average-color';

const IMAGE_SERVICE_PROFILES = {
  splash: 'mag-996x562',
  square: 'mag-140x140',
  cover: 'mag-280x373',
  teaser: 'mag-474x268',
  original: 'original',
} as const;

const IMAGE_DETECTION_BLOCKLIST: string[] = [];

const EVE_SDK_ENDPOINTS: Record<Environment, string> = {
  int: `https://eve-int.p7s1.io`,
  preprod: `https://eve-preprod.p7s1.io`,
  prod: `https://eve.p7s1.io`,
};

type ImageServiceProfile = keyof typeof IMAGE_SERVICE_PROFILES;

export const getImageUrl = (
  base: string,
  profile: ImageServiceProfile,
): string =>
  `${base.replace(/^http:\/\//, 'https://')}/profile:${
    IMAGE_SERVICE_PROFILES[profile]
  }?v=1&origin=${encodeURIComponent(document.location.origin)}`;

const supportsColorDetection = (url: string): boolean =>
  !IMAGE_DETECTION_BLOCKLIST.some((item) => url.startsWith(item));

export const getColorForImageElement = async (
  image: HTMLImageElement,
): Promise<string | undefined> => {
  if (supportsColorDetection(image.src)) {
    try {
      const fac = new FastAverageColor();
      const averageColorResult = fac.getColor(image);
      return averageColorResult.hex;
    } catch {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getColorForImageUrl = async (
  url: string,
): Promise<string | undefined> => {
  if (supportsColorDetection(url)) {
    try {
      const fac = new FastAverageColor();
      const averageColorResult = await fac.getColorAsync(url);
      return averageColorResult.hex;
    } catch {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getSdkImage = (env: Environment, url: string): string =>
  `${EVE_SDK_ENDPOINTS[env]}/sdk/web/images/${url}?origin=${encodeURIComponent(
    document.location.origin,
  )}`;

export const getSdkAdImage = (env: Environment, url: string): string =>
  `${EVE_SDK_ENDPOINTS[env]}/sdk/web/ads/${url}?origin=${encodeURIComponent(
    document.location.origin,
  )}`;
