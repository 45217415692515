// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Battle_battle__intro-icon__2OKL0{display:flex;justify-content:center;color:var(--ds-colors-surface-on-base-50)}.Battle_battle__intro-logo__3NouA{display:flex;justify-content:center;margin-bottom:var(--ds-space-small-md)}.Battle_battle__auth-wall-illustration__3CLBR{display:block;width:auto;height:var(--ds-size-small-3xl);margin:0 auto;color:var(--ds-colors-surface-on-base-50)}", "",{"version":3,"sources":["webpack://src/steps/Battle.module.scss"],"names":[],"mappings":"AAIA,kCACE,YAAA,CACA,sBAAA,CACA,yCAAA,CAGF,kCACE,YAAA,CACA,sBAAA,CACA,sCAAA,CAGF,8CACE,aAAA,CACA,UAAA,CACA,+BAAA,CACA,aAAA,CACA,yCAAA","sourcesContent":["@import '../scss/breakpoints';\n@import '../scss/typography';\n@import '../scss/dimensions';\n\n.battle__intro-icon {\n  display: flex;\n  justify-content: center;\n  color: var(--ds-colors-surface-on-base-50);\n}\n\n.battle__intro-logo {\n  display: flex;\n  justify-content: center;\n  margin-bottom: var(--ds-space-small-md);\n}\n\n.battle__auth-wall-illustration {\n  display: block;\n  width: auto;\n  height: var(--ds-size-small-3xl);\n  margin: 0 auto;\n  color: var(--ds-colors-surface-on-base-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"battle__intro-icon": "Battle_battle__intro-icon__2OKL0",
	"battle__intro-logo": "Battle_battle__intro-logo__3NouA",
	"battle__auth-wall-illustration": "Battle_battle__auth-wall-illustration__3CLBR"
};
export default ___CSS_LOADER_EXPORT___;
