import { FC, Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import { Play } from './Icon';
import { ResultsAd } from '../utils/ads';
import { AdTracking } from './AdTracking';

import styles from './Results.module.scss';

export type ResultsItemProps = {
  type?: 'default' | 'video';
  image?: string;
  label: string;
  active?: boolean;
  voted?: boolean;
  share?: string;
  self?: ReactNode;
  badge?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
};

export type ResultsProps = {
  items: ResultsItemProps[];
  unobtrusive?: boolean;
  abstracted?: boolean;
  ads?: ResultsAd[];
};

export const Results: FC<ResultsProps> = ({
  items,
  unobtrusive,
  abstracted,
  ads = [],
}) => {
  const clonedAds = Array.from(ads);

  return (
    <div className={styles.results}>
      {items.map(
        (
          {
            type = 'default',
            image,
            label,
            active,
            voted,
            share,
            self,
            badge,
            onClick,
            onMouseEnter,
          },
          index,
        ) => {
          const ad: ResultsAd | undefined =
            index > 0 && index % 3 === 0 ? clonedAds.shift() : undefined;
          return (
            <Fragment key={label}>
              {index > 0 ? (
                unobtrusive ? (
                  <div className={styles.results__spacer} />
                ) : (
                  <div className={styles.results__divider}>vs</div>
                )
              ) : null}
              {ad ? (
                <>
                  <AdTracking
                    group={`${index + 1}_${ad.tracking.name}`}
                    label={document.location.pathname}>
                    <div
                      className={styles.results__ad}
                      onClick={() => ad.onClick()}
                      style={{
                        '--ad-background': ad.backgroundColor,
                        '--ad-color': ad.textColor,
                      }}>
                      <div className={styles['results__badge']}>Anzeige</div>
                      <div className={styles['results__ad-cover']}>
                        <img
                          src={ad.coverUrl}
                          alt={ad.title}
                          className={styles['results__ad-cover-image']}
                        />
                      </div>
                      <div className={styles['results__ad-content']}>
                        <div className={styles['results__ad-logo']}>
                          <img
                            src={ad.logoUrl}
                            alt={ad.title}
                            className={styles['results__ad-logo-image']}
                          />
                        </div>
                        <div className={styles['results__ad-title']}>
                          {ad.title}
                        </div>
                      </div>
                    </div>
                  </AdTracking>
                  {unobtrusive ? (
                    <div className={styles.results__spacer} />
                  ) : (
                    <div className={styles.results__divider}>vs</div>
                  )}
                </>
              ) : null}
              <div
                className={classNames(
                  styles.results__item,
                  styles[`results__item--${type}`],
                  {
                    [styles['results__item--active']]: active,
                    [styles['results__item--voted']]: voted,
                    [styles['results__item--share']]: share,
                    [styles['results__item--badge']]: badge,
                    [styles['results__item--clickable']]: onClick,
                  },
                )}
                onMouseEnter={() => onMouseEnter?.()}
                onClick={() => onClick?.()}>
                {!abstracted && share ? (
                  <div
                    className={styles.results__bar}
                    style={{
                      width: `${share}%`,
                    }}
                  />
                ) : null}
                {badge ? (
                  <div className={styles.results__badge}>{badge}</div>
                ) : null}
                <div className={styles.results__content}>
                  {image ? (
                    <div className={styles.results__visual}>
                      <img
                        src={image}
                        alt={label}
                        className={styles.results__image}
                      />
                      {type === 'video' ? (
                        <div className={styles.results__icon}>
                          <Play />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className={styles.results__label}>{label}</div>
                  {voted && self ? (
                    <div className={styles.results__self}>{self}</div>
                  ) : null}
                  {share ? (
                    <div className={styles.results__share}>
                      {!abstracted ? `${share}%` : `${index + 1}`}
                    </div>
                  ) : null}
                </div>
              </div>
            </Fragment>
          );
        },
      )}
    </div>
  );
};
