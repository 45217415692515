import { FC, useEffect, useState } from 'react';
import { addVote, Voting as VotingType } from '@data/eve-sdk-web';
import { Options as ConfettiOptions } from 'canvas-confetti';

import { getVotingStep } from '../utils/votings';
import { useStep } from '../utils/step';
import { Modal } from '../components/Modal';
import { Tiles } from '../components/Tiles';
import { Button } from '../components/Button';
import { Headline } from '../components/Headline';
import { Stack } from '../components/Stack';
import { getColorForImageUrl, getImageUrl } from '../utils/images';
import { getInstance } from '../utils/confetti';
import { useColor } from '../utils/colors';
import { useConfig } from '../utils/config';

export type VotingProps = {
  voting: VotingType;
};

export const Voting: FC<VotingProps> = ({ voting }) => {
  const { step, goToStart, goToNextStep } = useStep();
  const { setColor } = useColor();
  const { context } = useConfig();
  const item = getVotingStep(voting);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    (item?.dimensions ?? []).find((dimension) => dimension.userVote)?.id,
  );
  const confetti = getInstance();

  const makeShot = async (particleRatio: number, opts: ConfettiOptions) => {
    if (confetti) {
      await confetti({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  };

  const fireConfetti = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const initColor = async () => {
    const userVote = (item?.dimensions ?? []).find(
      (dimension) => dimension.userVote,
    );
    if (userVote?.image?.url) {
      const averageColorResult = await getColorForImageUrl(
        getImageUrl(userVote.image.url, 'square'),
      );
      setColor(averageColorResult);
    }
  };

  useEffect(() => {
    initColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voting]);

  return (
    <Modal
      id="voting"
      open={step === 'Voting'}
      onClose={async () => {
        const userVote = (item?.dimensions ?? []).find(
          (dimension) => dimension.userVote,
        );
        if (userVote) {
          setSelectedItem(userVote.id);
          if (userVote.image?.url) {
            const averageColorResult = await getColorForImageUrl(
              getImageUrl(userVote.image.url, 'square'),
            );
            setColor(averageColorResult);
          }
        }
        goToStart();
      }}
      footer={
        selectedItem ? (
          <Button
            label="Stimme bestätigen"
            onClick={async () => {
              if (selectedItem && item?.bucketId) {
                addVote(context, item.bucketId, selectedItem, 1);
                fireConfetti();
                goToNextStep();
              }
            }}
          />
        ) : null
      }>
      <Stack variant="medium">
        <Headline level={4} align="center">
          {voting.title}
        </Headline>
        <Headline level={6} align="center">
          Stimme jetzt für deinen Favoriten!
        </Headline>
        <Stack variant="narrow">
          <Tiles
            items={(item?.dimensions ?? []).map(({ id, image, title }) => ({
              image: image?.url ? getImageUrl(image.url, 'square') : undefined,
              label: title,
              active: selectedItem === id,
              onClick: async () => {
                if (selectedItem === id) {
                  if (voting.image) {
                    const averageColorResult = await getColorForImageUrl(
                      getImageUrl(voting.image.url, 'splash'),
                    );
                    setColor(averageColorResult);
                  }
                  setSelectedItem(undefined);
                } else {
                  if (image?.url) {
                    const averageColorResult = await getColorForImageUrl(
                      getImageUrl(image.url, 'square'),
                    );
                    setColor(averageColorResult);
                  }
                  setSelectedItem(id);
                }
              },
            }))}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
