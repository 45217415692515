import { FC, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Cross } from './Icon';
import { AdTracking } from './AdTracking';
import { useConfig } from '../utils/config';
import { lockBodyScrolling, unlockBodyScrolling } from '../utils/scroll';
import { isModalAd, ModalAd } from '../utils/ads';

import styles from './Modal.module.scss';

export type ModalVariant = 'default' | 'small' | 'medium' | 'large';

export type ModalProps = {
  id: string;
  coverImage?: string;
  variant?: ModalVariant;
  align?: 'center';
  open?: boolean;
  seamless?: boolean;
  footer?: ReactNode;
  ad?: ModalAd | ReactNode;
  onClose: () => void;
};

export const Modal: FC<ModalProps> = ({
  id,
  coverImage,
  variant = 'default',
  align,
  open,
  seamless,
  children,
  footer,
  ad,
  onClose,
}) => {
  const { variant: layoutVariant, integration } = useConfig();

  useEffect(() => {
    if (open) {
      lockBodyScrolling(id);
    } else {
      unlockBodyScrolling(id);
    }
  }, [id, open]);

  return createPortal(
    <AnimatePresence>
      {open ? (
        <div
          className={classNames(
            styles.modal,
            styles[`modal--${variant}`],
            styles[`modal--in-${integration}`],
            styles[`modal--${align}`],
            {
              [styles['modal--inline']]: layoutVariant === 'inline',
              [styles['modal--floating']]: layoutVariant !== 'inline',
              [styles['modal--seamless']]: seamless,
            },
            styles[`modal--${footer ? 'with' : 'without'}-footer`],
            styles[`modal--${ad ? 'with' : 'without'}-ad`],
          )}
          data-modal={id}>
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles['modal__backdrop']}
          />
          <motion.div
            key="layer"
            initial={{ opacity: 0, scale: 0.9, y: '50%' }}
            animate={{ opacity: 1, scale: 1, y: '0%' }}
            exit={{ opacity: 0, scale: 0.9, y: '50%' }}
            className={styles['modal__layer']}>
            <div className={styles['modal__main']}>
              <div className={styles['modal__close']} onClick={() => onClose()}>
                <Cross size={20} />
              </div>
              <div
                className={styles['modal__content']}
                data-element-visibility-gate>
                {coverImage ? (
                  <div className={styles['modal__visual']}>
                    <img
                      src={coverImage}
                      alt=""
                      className={styles['modal__image']}
                    />
                    {ad ? (
                      isModalAd(ad) ? (
                        <AdTracking
                          group={ad.tracking.name}
                          label={document.location.pathname}>
                          <div className={styles['modal__ad-backdrop']} />
                          <div className={styles['modal__ad-content']}>
                            <div
                              className={styles.modal__ad}
                              onClick={() => ad.onClick()}>
                              <div className={styles['modal__badge']}>
                                Anzeige
                              </div>
                              <div className={styles['modal__ad-cover']}>
                                <img
                                  src={ad.coverUrl}
                                  alt={ad.title}
                                  className={styles['modal__ad-cover-image']}
                                />
                              </div>
                            </div>
                          </div>
                        </AdTracking>
                      ) : (
                        <>
                          <div className={styles['modal__ad-backdrop']} />
                          <div className={styles['modal__ad-frame']}>{ad}</div>
                        </>
                      )
                    ) : null}
                  </div>
                ) : null}
                <div className={styles['modal__slot']}>{children}</div>
              </div>
              {footer ? (
                <motion.div
                  key="footer"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={styles['modal__footer']}>
                  {footer}
                </motion.div>
              ) : null}
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>,
    document.getElementById('eve-portal') ?? document.body,
  );
};
