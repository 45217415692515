import { FC } from 'react';
import { Button } from './Button';
import { Copy } from './Copy';
import { Headline } from './Headline';

import styles from './LaneTeaser.module.scss';

export type LaneTeaserProps = {
  title: string;
  subline: string;
  action?: string;
  url?: string;
  onClick?: () => void;
};

export const LaneTeaser: FC<LaneTeaserProps> = ({
  title,
  subline,
  action,
  url,
  onClick,
}) => {
  return (
    <div className={styles['lane-teaser']}>
      <div className={styles['lane-teaser__content']}>
        <div className={styles['lane-teaser__title']}>
          <Headline level={5}>{title}</Headline>
        </div>
        <div className={styles['lane-teaser__subline']}>
          <Copy size="medium">{subline}</Copy>
        </div>
        <div className={styles['lane-teaser__action']}>
          <Button
            label={action}
            onClick={() => {
              if (url) {
                window.open(url);
              }
              onClick?.();
            }}
          />
        </div>
      </div>
    </div>
  );
};
