// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_app__2ziFi{--magellan-spacing-small: 90px;--magellan-spacing-large: 110px;position:relative;max-width:700px;margin:0 auto}.App_app__confetti__1NLAr{position:fixed;top:0px;left:0px;z-index:123456;width:100%;height:100%;pointer-events:none}.App_app--lane__1YZ1e,.App_app--battle-lane__2kY_N,.App_app--monterosa-teaser__2DxLT{max-width:100%}", "",{"version":3,"sources":["webpack://src/App.module.scss","webpack://src/scss/_dimensions.scss"],"names":[],"mappings":"AAEA,gBACE,8BAAA,CACA,+BAAA,CAEA,iBAAA,CACA,eCJkB,CDKlB,aAAA,CAGF,0BACE,cAAA,CACA,OAAA,CACA,QAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CAGF,qFAGE,cAAA","sourcesContent":["@import './scss/dimensions';\n\n.app {\n  --magellan-spacing-small: 90px;\n  --magellan-spacing-large: 110px;\n\n  position: relative;\n  max-width: $max-width-regular;\n  margin: 0 auto;\n}\n\n.app__confetti {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  z-index: 123456;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n\n.app--lane,\n.app--battle-lane,\n.app--monterosa-teaser {\n  max-width: 100%;\n}\n","@use 'sass:math';\n\n$max-width-narrow: 400px;\n$max-width-regular: 700px;\n$max-width-wide: 900px;\n\n$modal-max-width-small: 360px;\n$modal-max-width-medium: 520px;\n$modal-max-width-regular: 800px;\n\n$lane-item-width-small: calc(\n  100vw - (3 * var(--ds-space-small-sm)) - var(--ds-space-small-xl)\n);\n$lane-item-width-large: 280px;\n$lane-item-width-large-lenghty: 260px;\n$lane-item-spacing-small: var(--ds-space-small-md);\n$lane-item-spacing-large: var(--ds-space-small-lg);\n\n$card-ratio: math.div(528, 308);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App_app__2ziFi",
	"app__confetti": "App_app__confetti__1NLAr",
	"app--lane": "App_app--lane__1YZ1e",
	"app--battle-lane": "App_app--battle-lane__2kY_N",
	"app--monterosa-teaser": "App_app--monterosa-teaser__2DxLT"
};
export default ___CSS_LOADER_EXPORT___;
