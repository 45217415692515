import { FC, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import { Button } from '../components/Button';
import { getColorForImageElement } from '../utils/images';
import { useColor } from '../utils/colors';

import styles from './FloatingTeaser.module.scss';

export type FloatingTeaserProps = {
  image: string;
  title: string;
  action?: string;
  visible?: boolean;
  onClick?: () => void;
};

export const FloatingTeaser: FC<FloatingTeaserProps> = ({
  image,
  title,
  action,
  visible = true,
  onClick,
}) => {
  const { setColor } = useColor();
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopUp(true);
    }, 3 * 1000);
  }, []);

  return (
    <div
      className={classNames(styles['floating-teaser'], {
        [styles['floating-teaser--visible']]: visible,
      })}>
      {image ? (
        <div
          className={styles['floating-teaser__visual']}
          onClick={() => {
            if (showPopUp) {
              setShowPopUp(false);
              onClick?.();
            } else {
              setShowPopUp(true);
            }
          }}>
          <img
            crossOrigin="anonymous"
            src={image}
            alt={title}
            className={styles['floating-teaser__image']}
            onLoad={async (e) => {
              const averageColorResult = await getColorForImageElement(
                e.target as HTMLImageElement,
              );
              setColor(averageColorResult);
            }}
          />
        </div>
      ) : null}
      <AnimatePresence>
        <motion.div
          animate={showPopUp ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, scale: 1, x: 0, y: 0 },
            closed: { opacity: 0, scale: 0.5, x: 50, y: 50 },
            initial: { opacity: 0, scale: 0.5, x: 50, y: 50 },
          }}
          initial={false}
          className={styles['floating-teaser__content']}>
          <div className={styles['floating-teaser__title']}>{title}</div>
          {action ? (
            <div className={styles['floating-teaser__action']}>
              <Button
                variant="ghost"
                label={action}
                onClick={() => {
                  setShowPopUp(false);
                  onClick?.();
                }}
              />
            </div>
          ) : null}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
