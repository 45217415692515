import { FC, Fragment } from 'react';
import classNames from 'classnames';

import styles from './Tiles.module.scss';

export type TilesItemProps = {
  image?: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export type TilesProps = {
  items: TilesItemProps[];
};

export const Tiles: FC<TilesProps> = ({ items }) => {
  return (
    <div className={styles.tiles}>
      {items.map(({ image, label, active, onClick }, index) => (
        <Fragment key={label}>
          {index > 0 ? <div className={styles.tiles__divider}>vs</div> : null}
          <div
            className={classNames(styles.tiles__item, {
              [styles['tiles__item--active']]: active,
            })}
            onClick={() => onClick?.()}>
            <div className={styles.tiles__content}>
              <div className={styles.tiles__label}>{label}</div>
            </div>
            {image ? (
              <img src={image} alt={label} className={styles.tiles__visual} />
            ) : null}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
