import { getMiraConfig, MIRA_KEY } from './mira';

export const participate = async (
  raffleId: string,
  miraToken: string,
  provider?: string,
): Promise<boolean> => {
  const config = getMiraConfig();

  if (!config) {
    return false;
  }

  const body: {
    id: string;
    partner?: string;
  } = {
    id: raffleId,
  };

  if (provider === 'gewinnarena') {
    body.partner = 'ga';
  }

  try {
    const response = await fetch(
      `https://${config.endpoint}/participations/v1`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          key: MIRA_KEY,
          miratoken: miraToken,
        },
        body: JSON.stringify(body),
      },
    );
    return response.status === 200;
  } catch (error) {
    return false;
  }
};
