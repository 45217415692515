import { createContext, useContext } from 'react';

export type ColorApi = {
  color?: string;
  setColor: (newColor?: string) => void;
};

export const ColorContext = createContext<ColorApi>({
  color: undefined,
  setColor: () => undefined,
});

export const useColor = (): ColorApi => useContext(ColorContext);
