const correctDimensions: Record<string, number> = {
  // Lucky Stars: Becker
  '7454ea96-521a-4aff-bf5f-2da306337ef2': 2,
  'c4b7adac-f3fc-46cc-a9d7-c6d06faccdd5': 1,
  '3b474ad6-cf00-4a9e-bc5d-d7f62f6a6bf6': 1,
  'a2bdc84f-a1cc-4c5c-b362-890bdf917f15': 3,
  '74adc3e3-7fcd-4966-b3a6-fc5df48378e9': 2,
  // Lucky Stars: Burdecki
  '57377da0-3dbc-4c76-8cf8-963cc4da5dbe': 2,
  '2bbe4ab2-12bf-4c91-b95f-723a81836049': 1,
  'becf0c54-f636-48e8-b8ab-5207a9533c50': 1,
  '075d7f34-14fb-435c-9139-5a6d7729a868': 3,
  'fa6f781-10c7-4744-8435-234e838efc42': 2,
  // Lucky Stars: Rosin
  '12e12e03-9937-4317-9f86-308d8b5ca93e': 2,
  '3c10404c-f0db-4529-88b8-2fef8f33a345': 1,
  '34e26ea0-307b-42d8-a6b6-e65aadf31399': 3,
  'b012ea14-aeb9-4c03-a502-118497d389ab': 2,
  'a198b308-27b8-4824-a20c-93fb9453a57c': 1,
  // Lucky Stars: Ceylan
  'a2305af8-945b-4012-8ae7-5dc0390058a7': 2,
  '3fcd0a07-16d7-4541-ad0a-6a130c8d47ec': 1,
  '2a954c3e-f133-4b13-9212-bba9b1df24fa': 1,
  '73cf1c00-a90b-4844-b138-ee3defa6dd92': 3,
  '61dd7e76-70f8-4457-a1e8-d471d40cff27': 2,
  // Lucky Stars: Karasek
  '17138881-8c73-40a1-88c1-da66e560b9ce': 2,
  'beb54d63-02c6-48a6-89e1-eed15b5277f6': 1,
  'ce060696-b7c7-47e6-a887-2b5513a40f88': 1,
  'e4fbaab4-7026-4527-a881-62ec5f646602': 3,
  '5859a44c-fa93-4501-8c0e-f854d559f602': 2,
  // GNTM
  '089592ca-d542-4619-b73f-0ad1bddd5540': 2,
  '189386f3-a2fc-45b9-86f5-52e97c71ea57': 4,
  '2e2bebf2-b8e2-42c2-90b8-c5defc5b5188': 3,
  '3cd8d6d9-dc8d-4285-a83f-34187d8c11c2': 4,
  '57ec74e2-e258-4275-9ba7-ab4d5a338f41': 3,
  '5a977938-005e-4f31-9168-5aa5d7edc962': 1,
  '5c3d1d00-d54d-4f00-90f2-b210463348e3': 3,
  '659f5e7d-81c3-4127-82ac-4e9fb5005e0b': 4,
  '6822a105-8a32-4411-83ae-cc570791f565': 2,
  'a0e01042-7552-4c44-8a72-0e6a13e3edf5': 4,
  'b32e4713-e4ae-44f8-b098-e49588410bb8': 2,
  'c01216cc-23e6-4727-96e1-fee59297fdd0': 4,
  'c47bf7ab-f3c8-41a2-96f4-9e6ea6a85212': 2,
  'ca791769-b71a-46cd-9546-d8f58a6cd17f': 3,
  'e18f0a7b-5de7-4e92-ad37-ead71f8834b9': 3,
  // ZAPPN VW ID.Buzz
  '50b824bb-dbba-4204-8e8b-66b05a8dcbd6': 2, // Wann feierte Stars Wars Premiere?
  '91a1e055-747e-4379-a3a1-37421347c206': 1, // Und mit welchem Modell legt VW seine letzte Premiere hin?
  'ed9a8635-340c-418b-9301-c3236ec31d1a': 1, // Was ist das Besondere am neuen ID Buzz?
  'ef5a6cb5-540c-4b22-b2a1-1fbde769d9ee': 3, // Welche elektrische Reichweite hat der ID Buzz?
  'a0acaf49-b31a-46da-ba15-d379303fe405': 2, // Wie wird der VW Bus liebevoll auch genannt?
  'c178c455-4a7d-416b-8097-d49a8c7d0b89': 2, // Welcher Superstar konnte als großer VW-Fan zum Partner gewonnen werden?
  'be0cde96-d84f-4272-91db-997e97eeaaee': 2, // Welchen VW Klassiker baute Ewan McGregor selbst zum E-Auto um?
  '14c68f27-f18f-4ae6-91e8-42c171fdafb1': 3, // Wen verkörperte Ewan McGregor in den Star Wars-Filmen?
  // BSF AT
  'fc63a240-e779-437c-8ca6-62ce676f1dab': 2, // Wo wohnt der erste neue Bauer, unser Edi?
  'b17a37a2-6bf5-492b-beba-13e3d468ba99': 1, // Was ist Edi bei seiner Traumfrau wichtig? Nenne eine Charaktereigenschaft
  '87b6089f-cd56-4824-aa83-6944d0c61f9a': 2, // Seit wann ist Edi Single?
  '6eca2b20-507e-4f58-9d52-d365542e7423': 3, // Zeit für Bauer 2. Wie heißt Markus' Pferd, auf dem sogar Arabella eine Runde reitet?
  'c88c39cb-de0b-4a9c-8593-1a9c87e1b4a8': 3, // Wie beschreibt Pferdewirt Markus sich selbst?
  '80e46200-05fe-4f7b-924c-faed7f3a9704': 1, // Ritter Markus singt für Arabella eine Version welchen Liedes?
  'ab3d218d-ac9b-4ff2-baf3-edc037f8e605': 2, // Und schon geht's zu Bauer 3! Wo wohnt Sigi eigentlich?
  'ed045c76-ff2e-4167-9a68-e1a9b8d79dce': 2, // Wieviele Geschwister hat Sigi?
  '79ca018b-0bca-4a09-9bab-540396843f2d': 3, // Bei welchen Frauen wird Sigi schwach?
  'e7c0463c-e866-4c4b-8450-a84cec576406': 1, // Bauer 4 will zu dir. Marius liebt neben dem Landleben auch ...
  '64fd6a19-b0a1-4d20-8858-a81d13b550f8': 2, // Vervollständige den Satz mit Martins Worten. Küssen …
  '87bc8c10-d853-4ef6-8898-24a2d9ae7575': 3, // Wo schaut der Singlebauer bei einer Frau als Erstes hin?
  '135090c4-cb59-407e-b684-c1254a398530': 3, // Kommt das Beste wirklich zum Schluss? Wie heißt dieser fesche Forstwirt?
  'a7d02841-f5bd-4f12-93a4-6c6d2c1dfca6': 1, // Arabella hat Hunger. Was kann Alex am besten Kochen?
  '48030bc2-887c-4f46-be86-e40bf82d93d2': 2, // Wie steht Alexander zu Tattoos?
  '463b9f87-44a7-4b4d-846f-78d7c29db6af': 3, // Endspurt mit Bauer Alexander. Aufgepasst? Wieviele Gemüsesorten baut er an?
  '2678a378-fbad-4bfd-b1ed-27309aa158b4': 2, // Piet sucht eine Frau, die ein Wort am besten beschreibt. Welches?
  'c283dbe5-1612-4455-9b2f-bf7b427fbe4c': 2, // Welchen Luxus hat der Hirte auf seiner Alm?
  '0be82400-7361-4a80-9c94-3366fb37b5ce': 1, // Und schon geht's zu Bauer 6 und seiner Lieblingskuh. Wie heißt das Gespann?
  'cb8f0f70-bc43-405d-aec0-d2b3394b9261': 3, // Welche Schwäche hat der Schafbauer?
  '7ecaa13f-0260-4034-b84f-456851fbfaa5': 3, // Wer ist Dominiks wichtigster Unterstützer bei der Arbeit?
  '89d6a1a5-4214-4e3e-b3be-ae60220a4d7d': 2, // Dominik ist der 5. neue Bauer und ist wie alt?
  '8e666ad5-36f8-4e9c-9a33-1bd28898314f': 2, // Welchen Typ Frau sucht Marius?
  '564063fd-4ca6-470d-80da-bf0ebb73aa96': 2, // Wieviel Hektar bewirtschaftet Marius?
  // BSF AT Part 2
  '5a6f5e51-b00a-418c-a455-d11604ee76d3': 3, // Wozu sagt Florian bei einer Frau nie Nein?
  '6876e5d2-6f63-4dcd-aca7-4f6f36a60c3a': 1, // Was gefällt Florian richtig gut bei einer Frau?
  '8ed6ea58-a7f1-4e99-86f7-a1335482a4c5': 2, // Letzte Quizrunde mit Florian. Wie heißt sein treuester Begleiter?
  'e4b2c249-8cf2-4f62-82bc-73ed9e143cbf': 1, // Was hat Erwin unerwarteterweise gleich um die Ecke?
  '0ad92f77-b4eb-4a6f-b053-23b198f66a81': 2, // Optisch gefallen Erwin Frauen mit ....
  '82b8a451-5d1d-4d35-906b-206153a0338e': 3, // Hallo, Bauer 6, in luftiger Höh'! Wie hoch liegt Erwins Hof
  '8b9de360-ab6a-40c9-a8ac-6063ff2cf62f': 1, // Wie beschreibt Pferdewirtin Steffi sich selbst?
  '0a281d60-65db-421c-a7c1-8de114eb7783': 1, // Mit wem kuschelt Steffi abends, da kein Mann im Haus ist?
  'be32e25d-3d02-4ae6-837a-a6964ff3077a': 3, // Es geht turbulent weiter. Steffi tut alles für ihre Pferde. Sie haben sogar ein ...
  '9adb82ad-a915-4eb4-b86d-ce911046e008': 3, // Wer hat Robert bei "Bauer sucht Frau" angemeldet?
  '5359055b-b8d7-4d1d-a2c4-60f5439b2904': 2, // Singlebauer Robert hat schon wieviele Kinder?
  '4b441911-cc8e-4cd0-b226-5177c5a200a8': 3, // Bauer 4 überrascht als Quereinsteiger. Er ist nämlich eigentlich gelernter ...
  '105c50bb-a304-4536-9344-b8920a1458d0': 1, // Was ist Helmuts allergrößter Wunsch?
  'bb88b9b8-47b2-4764-8578-5611ba416d13': 1, // Helmuts Meinung nach ist man als Landwirt ....
  '3e607660-f7eb-401e-b757-2ca85ef4c856': 2, // Da geht's auch schon mit Bauer Helmut weiter! Wieviel Hektar gehören zu seinem Betrieb?
  '4f78f903-a5f8-47fc-bc04-4fc60db3aafc': 2, // Wie steht Franz zu Raucherin` als Partnerin?
  '4abd8474-65ab-4e61-a59f-bebc9dfaa3f2': 3, // Wo lebt der Almbauer - und das leider noch alleine ...
  '14ea4a8d-444d-4348-ad9b-dd2806fb93cb': 2, // Zeit für Bauer 2. Wie heißt Franz' Lieblingskuh?
  'f2344d23-30d1-4a0a-8407-219abc0552de': 3, // Rudis Opa und Rudis Papa waren es schon. Und Rudi ist es auch. Was ist gemeint?
  '1527004d-8551-417f-beb0-67025d5c94c9': 2, // Wie groß ist der fesche Milchbauer?
  'ef7f6cd9-0c72-4fdb-8342-b023291bec4d': 2, // Zack und schon geht's zur Folge 2. Wie hei´ßt dieser junge Singlebauer?
};

export const supportsCorrectnessCheck = (votingId: string): boolean =>
  typeof correctDimensions[votingId] !== 'undefined';

export const isCorrectDimension = (
  votingId: string,
  dimension: number | string,
): boolean =>
  Boolean(
    correctDimensions[votingId] &&
      correctDimensions[votingId] ===
        parseInt(String(dimension).replace(/^d/, ''), 10),
  );

export const convertClipTitleToArtists = (title: string): string =>
  title.split(/\s*:\s*/)[0].split(/\s+mit\s+/)[0];
