import { createContext, FC, useContext } from 'react';
import { Theme, THEMES } from '@data/eve-sdk-web-ui-types';

const DEFAULT_THEME_VERSION = '1';

export enum Environment {
  INT = 'int',
  PROD = 'prod',
}

export const getCdnEndpoint = (env?: Environment): string => {
  switch (env) {
    case Environment.INT:
      return 'https://s-int.p7s1.io';
    default:
      return 'https://s.p7s1.io';
  }
};

export const getThemeStylesheetUrls = ({
  env,
  version,
  themes,
}: {
  env?: Environment;
  version?: string;
  themes?: Theme[];
}): string[] => {
  const endpoint = getCdnEndpoint(env ?? Environment.PROD);
  return (themes ?? THEMES).map(
    (theme) =>
      `${endpoint}/tokens/${
        version ?? DEFAULT_THEME_VERSION
      }/web/namespaced/${theme}.css`,
  );
};

export const getThemeWrapperClassName = (theme: Theme): string =>
  `ds-theme-${theme}`;

export const loadFonts = ({
  env,
  version,
}: {
  env?: Environment;
  version?: string;
}): void => {
  const endpoint = getCdnEndpoint(env ?? Environment.PROD);
  const url = `${endpoint}/tokens/${
    version ?? DEFAULT_THEME_VERSION
  }/web/fonts.css`;
  const cssId = 'ds-theme-fonts';
  if (!document.getElementById(cssId)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    head.appendChild(link);
  }
};

export type ThemeApi = {
  theme: Theme;
  setTheme: (newTheme: Theme) => void;
};

export const ThemeContext = createContext<ThemeApi>({
  theme: 'default-dark',
  setTheme: () => undefined,
});

export const useTheme = (): ThemeApi => useContext(ThemeContext);

export const ThemeProvider: FC<ThemeApi> = ({ theme, setTheme, children }) => {
  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}>
      <div className={getThemeWrapperClassName(theme)}>{children}</div>
    </ThemeContext.Provider>
  );
};
