const breakpointMedium = 500;
const breakpointLarge = 800;

export const isMediumUp = (): boolean =>
  window.matchMedia(`(min-width: ${breakpointMedium}px)`).matches;

export const isMediumDown = (): boolean =>
  window.matchMedia(`(max-width: ${breakpointMedium - 1}px)`).matches;

export const isLargeUp = (): boolean =>
  window.matchMedia(`(min-width: ${breakpointLarge}px)`).matches;

export const isLargeDown = (): boolean =>
  window.matchMedia(`(max-width: ${breakpointLarge - 1}px)`).matches;

export const isUp = (breakpoint: number): boolean =>
  window.matchMedia(`(min-width: ${breakpoint}px)`).matches;

export const isDown = (breakpoint: number): boolean =>
  window.matchMedia(`(max-width: ${breakpoint - 1}px)`).matches;
