import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  GroupStep,
  isResultsStep,
  isVotingStep,
  ResultsStep,
  Voting,
  VotingDimension,
  VotingStep,
} from '@data/eve-sdk-web';
import debounce from 'lodash.debounce';
import { useConfig } from '../utils/config';
import { ActionTeaser } from '../components/ActionTeaser';
import { BattleLaneItem } from '../components/BattleLaneItem';
import { Modal } from '../components/Modal';
import { Stack } from '../components/Stack';
import { Headline } from '../components/Headline';
import { Copy } from '../components/Copy';
import {
  getGroupSteps,
  getResultsStep,
  isRevealed,
  isVoteable,
} from '../utils/votings';
import { Results } from '../components/Results';
import { Avatar } from '../components/Avatar';
import { getImageUrl, getSdkImage } from '../utils/images';
import { Bolt } from '../components/Icon';
import { OasisPlayer } from '../components/OasisPlayer';
import { Card } from '../components/Card';
import { onVisibilityChange, trackEvent } from '../utils/tracking';
import { getVideoIdForVotingDimension } from '../utils/media';
import { emit } from '../utils/bus';
import { LaneNavigator } from '../components/LaneNavigator';
import { FloatingTeaser } from '../components/FloatingTeaser';
import { sortBy } from '../utils/sorting';
import { resultsAds } from '../utils/ads';
import { isEnabled } from '../utils/toggles';
import { interpolate } from '../utils/ui';
import { getItem, setItem } from '../utils/storage';

import styles from './BattleLane.module.scss';

type FlattenedStep = {
  voting: Voting;
  group: GroupStep;
  step: VotingStep | ResultsStep;
};

const getRandomDimensionImageForStep = (
  step: VotingStep | ResultsStep,
): string | undefined => {
  const images: string[] = step.dimensions
    .map((dimension) =>
      dimension.image?.url ? getImageUrl(dimension.image.url, 'splash') : '',
    )
    .filter(Boolean);
  return images.length
    ? images[Math.floor(Math.random() * images.length)]
    : undefined;
};

const getUserVoteDimension = (
  step: VotingStep | ResultsStep,
): VotingDimension | undefined => {
  return Array.from(step.dimensions ?? []).find(
    (dimension) => dimension.userVote,
  );
};

const getHighestRankingDimension = (
  step: VotingStep | ResultsStep,
): VotingDimension => {
  const ranked = Array.from(step.dimensions ?? []).sort(
    (a, b) => (b.result?.number ?? 0) - (a.result?.number ?? 0),
  );
  return ranked[0];
};

const getDimensionImageForStepByRank = (
  step: VotingStep | ResultsStep,
): string | undefined => {
  const item = getHighestRankingDimension(step);
  const image: string | undefined = item.image?.url
    ? getImageUrl(item.image.url, 'splash')
    : undefined;
  return image;
};

const getRelevantStepsForGroup = (
  group: GroupStep,
): (VotingStep | ResultsStep)[] => {
  return group.steps.filter(
    (step) => isVotingStep(step) || isResultsStep(step),
  ) as (VotingStep | ResultsStep)[];
};

const getUniqueStepIdentifier = (item: FlattenedStep): string =>
  `${item.voting.flowId}:${item.group.id}:${item.step.id}`;

const flattenSteps = (votings: Voting[]): FlattenedStep[] => {
  const results: FlattenedStep[] = [];
  votings.forEach((voting) => {
    getGroupSteps(voting).forEach((group) => {
      getRelevantStepsForGroup(group).forEach((step) => {
        results.push({
          voting,
          group,
          step,
        });
      });
    });
  });
  return results;
};

const getCoverImageMap = (
  flattenedSteps: FlattenedStep[],
): Record<string, string | undefined> =>
  flattenedSteps.reduce((acc, item) => {
    const id = getUniqueStepIdentifier(item);
    return {
      ...acc,
      [id]: getRandomDimensionImageForStep(item.step),
    };
  }, {} as Record<string, string | undefined>);

export type BattleLaneApi = {
  count: number;
};

export const BattleLaneContext = createContext<BattleLaneApi>({
  count: 0,
});

export const useBattleLane = (): BattleLaneApi => useContext(BattleLaneContext);

export type BattleLaneProps = {
  votings: Voting[];
};

export const BattleLane: FC<BattleLaneProps> = ({ votings }) => {
  const { env, group: configGroup, name, integration, custom } = useConfig();
  const ref = useRef<HTMLDivElement>(null);
  const slider = useRef<HTMLDivElement | null>(null);
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false);
  const [showPlayerModal, setShowPlayerModal] = useState<boolean>(false);
  const [currentPlayerItem, setCurrentPlayerItem] = useState<
    VotingDimension | undefined
  >(undefined);
  const [isFirstItemVisible, setIsFirstItemVisible] = useState<boolean>(true);
  const [isLastItemVisible, setIsLastItemVisible] = useState<boolean>(false);
  const [showFloatingTeaser, setShowFloatingTeaser] = useState<boolean>(
    !getItem(`hints:floating:${custom?.floatingTeaserId ?? 'default'}`),
  );
  const firstBestRevealedVoting = useMemo(
    () =>
      votings.find((voting) => isRevealed(voting)) ??
      votings.find((voting) => isVoteable(voting)),
    [votings],
  );
  const activeVotings = useMemo(
    () => votings.filter((voting) => isVoteable(voting)),
    [votings],
  );
  const count = useMemo(
    () =>
      votings.reduce((acc, voting) => acc + getGroupSteps(voting).length, 0),
    [votings],
  );
  const revealStep = useMemo(
    () =>
      firstBestRevealedVoting
        ? getResultsStep(firstBestRevealedVoting)
        : undefined,
    [firstBestRevealedVoting],
  );
  const revealUserVoteDimension = useMemo(
    () => (revealStep ? getUserVoteDimension(revealStep) : undefined),
    [revealStep],
  );
  const revealHighestRankingDimension = useMemo(
    () => (revealStep ? getHighestRankingDimension(revealStep) : undefined),
    [revealStep],
  );
  const revealStepCoverImage = useMemo(
    () => (revealStep ? getDimensionImageForStepByRank(revealStep) : undefined),
    [revealStep],
  );
  const userVotes = useMemo(
    () =>
      Array.from(new Set((revealStep?.userVote ?? []).map((vote) => vote.id))),
    [revealStep],
  );
  const flattenedSteps = useMemo(
    () => flattenSteps(activeVotings),
    [activeVotings],
  );
  const flattenedStepsHash = useMemo(
    () =>
      flattenedSteps
        .map((item) => getUniqueStepIdentifier(item))
        .sort()
        .join('|'),
    [flattenedSteps],
  );
  const coverImages = useMemo(
    () => getCoverImageMap(flattenedSteps),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenedStepsHash],
  );

  const updateSliderState = (): void => {
    if (slider.current && slider.current.children.length) {
      setIsFirstItemVisible(
        Math.abs(
          slider.current.children[0].getBoundingClientRect().left -
            slider.current.getBoundingClientRect().left,
        ) < 10,
      );
      setIsLastItemVisible(
        slider.current.children[
          slider.current.children.length - 1
        ].getBoundingClientRect().right -
          slider.current.getBoundingClientRect().right <
          10,
      );
    }
  };

  const swipeHandler = (): void => {
    trackEvent('Voting Teaser Swipe', {
      group: String(configGroup),
      label: 'Lane',
    });
    updateSliderState();
  };

  const debouncedScrollHandler = useMemo(
    () => debounce(swipeHandler, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goToItem = (index: number): void => {
    if (slider.current) {
      slider.current.scrollTo({
        left: (slider.current.children[index] as HTMLDivElement).offsetLeft,
        behavior: 'smooth',
      });
      updateSliderState();
      setTimeout(updateSliderState);
    }
  };

  const getCurrentItem = (): number =>
    Array.from(slider.current?.children ?? []).findIndex(
      (child) =>
        slider.current &&
        child.getBoundingClientRect().left >=
          slider.current.getBoundingClientRect().left &&
        child.getBoundingClientRect().left +
          child.getBoundingClientRect().width <
          slider.current.getBoundingClientRect().left +
            slider.current.getBoundingClientRect().width,
    );

  const goToPreviousItem = (): void => {
    goToItem(Math.max(0, getCurrentItem() - 1));
  };

  const goToNextItem = (): void => {
    goToItem(Math.min(flattenedSteps.length - 1, getCurrentItem() + 1));
  };

  const isRevealOnlyRound =
    firstBestRevealedVoting &&
    !isVoteable(firstBestRevealedVoting) &&
    votings.length === 1;
  const isFinalRound =
    !!custom?.battleLaneEmulateFinalRound ||
    (firstBestRevealedVoting &&
      !isVoteable(firstBestRevealedVoting) &&
      votings.length > 1);

  useEffect(() => {
    if (ref.current) {
      onVisibilityChange(ref.current, (isVisible) => {
        if (isVisible) {
          trackEvent('Voting Component Impression', {
            group: String(configGroup),
            label: 'Lane',
          });
        }
      });
    }

    updateSliderState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BattleLaneContext.Provider
      value={{
        count: count,
      }}>
      <div
        ref={ref}
        className={classNames(styles['battle-lane'], {
          [styles[`battle-lane--${integration}`]]: integration,
          [styles[`battle-lane--${custom?.battleLaneModifier}`]]:
            custom?.battleLaneModifier,
          [styles['battle-lane--lengthy']]: count > 3,
        })}>
        <Stack gap="lg">
          <div className={styles['battle-lane__intro']}>
            <Stack gap="sm">
              <Bolt />
              <Headline level={5}>
                {isRevealOnlyRound
                  ? name
                    ? interpolate(
                        String(custom?.battleLaneIntroRevealOnlyHeadlineUser),
                        {
                          name,
                        },
                      )
                    : String(custom?.battleLaneIntroRevealOnlyHeadlineAnonymous)
                  : isFinalRound
                  ? name
                    ? interpolate(
                        String(custom?.battleLaneIntroFinalHeadlineUser),
                        {
                          name,
                        },
                      )
                    : String(custom?.battleLaneIntroFinalHeadlineAnonymous)
                  : name
                  ? interpolate(String(custom?.battleLaneIntroHeadlineUser), {
                      name,
                    })
                  : String(custom?.battleLaneIntroHeadlineAnonymous)}
              </Headline>
            </Stack>
          </div>
          <div className={styles['battle-lane__slider']}>
            <div
              className={classNames(
                styles['battle-lane__control'],
                styles['battle-lane__control--previous'],
              )}>
              <LaneNavigator
                direction="previous"
                disabled={isFirstItemVisible}
                onClick={() => {
                  goToPreviousItem();
                  trackEvent('Voting Teaser Lane Pagination Previous Click', {
                    group: String(configGroup),
                    label: 'Lane',
                  });
                }}
              />
            </div>
            <div
              ref={slider}
              className={styles['battle-lane__items']}
              onScroll={debouncedScrollHandler}>
              {isRevealOnlyRound ? (
                <div
                  className={classNames(
                    styles['battle-lane__item'],
                    styles['battle-lane__item--stretched'],
                  )}>
                  <Card
                    flippable={false}
                    icon="Trophy"
                    coverImage={revealStepCoverImage}
                    badge={String(custom?.battleLaneRevealBadge)}
                    onCoverClick={() => {
                      trackEvent('Voting Results Impression', {
                        group: String(configGroup),
                        label: 'Teaser',
                      });
                      setShowResultsModal(true);
                    }}
                    stretched
                    cover={() => (
                      <>
                        {revealUserVoteDimension ? (
                          <div className={styles['battle-lane__vote']}>
                            <div className={styles['battle-lane__vote-label']}>
                              Deine Antwort
                            </div>
                            <div className={styles['battle-lane__vote-value']}>
                              <Headline level={6}>
                                {revealUserVoteDimension?.title}
                              </Headline>
                            </div>
                          </div>
                        ) : null}
                        {revealHighestRankingDimension ? (
                          <div
                            className={classNames(
                              styles['battle-lane__vote'],
                              styles['battle-lane__vote--highlighted'],
                            )}>
                            <div className={styles['battle-lane__vote-label']}>
                              Gewonnen hat
                            </div>
                            <Headline level={6}>
                              {revealHighestRankingDimension?.title}
                            </Headline>
                            <div
                              className={styles['battle-lane__vote-appendix']}>
                              Sieh Dir das Ranking an
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                  />
                </div>
              ) : (
                flattenedSteps.map((item, index) => {
                  const { voting, group, step } = item;
                  const id = getUniqueStepIdentifier(item);
                  return (
                    <div
                      key={id}
                      className={classNames(styles['battle-lane__item'], {
                        [styles['battle-lane__item--stretched']]:
                          flattenedSteps.length < 2,
                      })}>
                      <BattleLaneItem
                        coverImage={coverImages[id]}
                        voting={voting}
                        group={group}
                        step={step}
                        badge={
                          !isFinalRound
                            ? String(custom?.battleLaneBadge)
                            : String(custom?.battleLaneFinalBadge)
                        }
                        index={index + 1}
                        previousStep={
                          (
                            flattenedSteps[index - 1] ??
                            flattenedSteps[flattenedSteps.length - 1]
                          )?.step
                        }
                        nextStep={
                          (flattenedSteps[index + 1] ?? flattenedSteps[0])?.step
                        }
                        isFinalRound={isFinalRound}
                        stretched={flattenedSteps.length < 2}
                      />
                    </div>
                  );
                })
              )}
            </div>
            <div
              className={classNames(
                styles['battle-lane__control'],
                styles['battle-lane__control--next'],
              )}>
              <LaneNavigator
                direction="next"
                disabled={isLastItemVisible}
                onClick={() => {
                  goToNextItem();
                  trackEvent('Voting Teaser Lane Pagination Next Click', {
                    group: String(configGroup),
                    label: 'Lane',
                  });
                }}
              />
            </div>
          </div>
        </Stack>
        {revealStep && !isRevealOnlyRound ? (
          <div className={styles['battle-lane__teaser']}>
            <ActionTeaser
              image={getSdkImage(
                env,
                String(custom?.battleLaneRevealTeaserVisual),
              )}
              title={
                !isFinalRound
                  ? String(custom?.battleLaneRevealTeaserTitle)
                  : String(custom?.battleLaneRevealTeaserFinalTitle)
              }
              text={
                custom?.battleLaneRevealTeaserText &&
                custom?.battleLaneRevealTeaserFinalText
                  ? !isFinalRound
                    ? String(custom?.battleLaneRevealTeaserText)
                    : String(custom?.battleLaneRevealTeaserFinalText)
                  : undefined
              }
              actionLabel={String(custom?.battleLaneRevealTeaserAction)}
              onClick={() => {
                trackEvent('Voting Results Impression', {
                  group: String(configGroup),
                  label: 'Teaser',
                });
                setShowResultsModal(true);
              }}
            />
          </div>
        ) : null}
        {revealStep ? (
          <>
            <Modal
              id="results"
              variant="medium"
              open={showResultsModal}
              onClose={() => setShowResultsModal(false)}>
              <Stack gap="xl">
                <Stack gap="xs">
                  <Headline level={4} align="center">
                    {isRevealOnlyRound
                      ? String(custom?.battleLaneResultsModalHeadlineRevealOnly)
                      : isFinalRound
                      ? String(custom?.battleLaneResultsModalHeadlineFinal)
                      : String(custom?.battleLaneResultsModalHeadline)}
                  </Headline>
                  <Headline level={6} align="center">
                    {isRevealOnlyRound
                      ? name
                        ? interpolate(
                            String(
                              custom?.battleLaneResultsModalSublineRevealOnlyUser,
                            ),
                            {
                              name,
                            },
                          )
                        : String(
                            custom?.battleLaneResultsModalSublineRevealOnlyAnonymous,
                          )
                      : isFinalRound
                      ? name
                        ? interpolate(
                            String(
                              custom?.battleLaneResultsModalSublineFinalUser,
                            ),
                            {
                              name,
                            },
                          )
                        : String(
                            custom?.battleLaneResultsModalSublineFinalAnonymous,
                          )
                      : name
                      ? interpolate(
                          String(custom?.battleLaneResultsModalSublineUser),
                          {
                            name,
                          },
                        )
                      : String(custom?.battleLaneResultsModalSublineAnonymous)}
                  </Headline>
                  {custom?.battleLaneResultsModalIntroRevealOnly &&
                  custom?.battleLaneResultsModalIntroFinal &&
                  custom?.battleLaneResultsModalIntro ? (
                    <Copy align="center">
                      {isRevealOnlyRound
                        ? String(custom?.battleLaneResultsModalIntroRevealOnly)
                        : isFinalRound
                        ? String(custom?.battleLaneResultsModalIntroFinal)
                        : String(custom?.battleLaneResultsModalIntro)}
                    </Copy>
                  ) : null}
                </Stack>
                <Results
                  ads={isEnabled('battleLaneAds') ? resultsAds : undefined}
                  items={sortBy<VotingDimension>(
                    revealStep.dimensions,
                    'result.number',
                    'desc',
                  )
                    .slice(0, 20)
                    .map((dimension, index, array) => {
                      const stepForCurrentDimension = flattenedSteps.find(
                        ({ step }) =>
                          step.dimensions.some(
                            (item) => item.id === dimension.id,
                          ),
                      );

                      return {
                        type: 'video',
                        image: dimension.image?.url
                          ? getImageUrl(dimension.image?.url, 'square')
                          : undefined,
                        label: dimension.title,
                        share: (dimension.result?.percentage ?? 0).toFixed(0),
                        voted: userVotes.includes(dimension.id),
                        self: <Avatar name={name} size="small" />,
                        badge: isFinalRound
                          ? String(custom?.battleLaneFinalBadge)
                          : dimension.designated
                          ? dimension.designatedTitle
                          : undefined,
                        onClick: () => {
                          trackEvent('Voting Result Teaser Click', {
                            group: String(configGroup),
                            label: `${dimension.title.trim()} - ${index + 1}/${
                              array.length
                            }`,
                          });
                          if (stepForCurrentDimension) {
                            setShowResultsModal(false);
                            emit(
                              `battle-lane-show-item:${stepForCurrentDimension.step.id}`,
                              {
                                dimension: dimension.id,
                              },
                            );
                          } else {
                            setCurrentPlayerItem(dimension);
                            setShowPlayerModal(true);
                          }
                        },
                      };
                    })}
                  unobtrusive
                  abstracted
                />
              </Stack>
            </Modal>
            <Modal
              id="player"
              variant="large"
              align="center"
              open={showPlayerModal}
              onClose={() => setShowPlayerModal(false)}>
              {currentPlayerItem ? (
                <Stack gap="xl">
                  <Headline level={4} align="center">
                    {currentPlayerItem.title}
                  </Headline>
                  <OasisPlayer
                    content={{
                      id: getVideoIdForVotingDimension(currentPlayerItem),
                    }}
                    onAdRollStart={() => {
                      trackEvent('Voting Results Ad Impression', {
                        group: String(configGroup),
                        label: 'Teaser',
                      });
                    }}
                  />
                </Stack>
              ) : null}
            </Modal>
          </>
        ) : null}
      </div>
      {showFloatingTeaser && custom && custom.battleLaneFloatingTeaser ? (
        <FloatingTeaser
          title={interpolate(String(custom.floatingTeaserTitle), {
            count: String(userVotes.length),
          })}
          action={String(custom.floatingTeaserAction)}
          image={String(custom.floatingTeaserImage)}
          onClick={
            custom.floatingTeaserUrl
              ? () => window.open(String(custom.floatingTeaserUrl))
              : () => {
                  if (flattenedSteps.length > 0) {
                    emit(`battle-lane-show-item:${flattenedSteps[0].step.id}`);
                  }
                  setItem(
                    `hints:floating:${custom?.floatingTeaserId ?? 'default'}`,
                    true,
                  );
                  setShowFloatingTeaser(false);
                }
          }
        />
      ) : null}
    </BattleLaneContext.Provider>
  );
};
