import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { on, off, emit, Handler } from '../utils/bus';
import { useConfig } from '../utils/config';

import styles from './Notifications.module.scss';

export type NotificationsItem = {
  id: string;
  title: string;
  text?: string;
};

export const useNotification =
  () =>
  (notification: NotificationsItem): void => {
    emit('notification', {
      notification,
    });
  };

export const Notifications: FC = () => {
  const { integration } = useConfig();
  const [items, setItems] = useState<NotificationsItem[]>([]);

  useEffect(() => {
    const handler: Handler = ({ notification }) => {
      setItems((items) => [notification, ...items]);

      setTimeout(() => {
        setItems((items) =>
          items.filter((item) => item.id !== notification.id),
        );
      }, 5 * 1000);
    };

    on('notification', handler);

    return () => off('notification', handler);
  }, []);

  return createPortal(
    <div
      className={classNames(
        styles.notifications,
        styles[`notifications--in-${integration}`],
      )}>
      <AnimatePresence>
        {items.map(({ id, title, text }) => (
          <motion.div
            key={id}
            initial={{ y: -15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 15, opacity: 0 }}
            transition={{
              duration: 0.5,
            }}
            className={styles['notifications__item']}>
            <div className={styles['notifications__title']}>{title}</div>
            {text ? (
              <div className={styles['notifications__text']}>{text}</div>
            ) : null}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>,
    document.getElementById('eve-portal') ?? document.body,
  );
};
