import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Voting } from '@data/eve-sdk-web';
import debounce from 'lodash.debounce';
import { LaneItem } from '../components/LaneItem';
import { LanePlaceholder } from '../components/LanePlaceholder';
import { useConfig } from '../utils/config';
import { onVisibilityChange, trackEvent } from '../utils/tracking';
import { Stack } from '../components/Stack';
import { Bolt } from '../components/Icon';
import { Headline } from '../components/Headline';
import { LaneNavigator } from '../components/LaneNavigator';
import { LaneTeaser } from '../components/LaneTeaser';
import { getResultsStep, getVotingStep } from '../utils/votings';
import { on } from '../utils/bus';
import { FloatingTeaser } from '../components/FloatingTeaser';
import { interpolate } from '../utils/ui';

import styles from './Lane.module.scss';

const MIN_LANE_LENGTH = 3;

export type LaneApi = {
  count: number;
};

export const LaneContext = createContext<LaneApi>({
  count: 0,
});

export const useLane = (): LaneApi => useContext(LaneContext);

export type LaneProps = {
  votings: Voting[];
};

export const Lane: FC<LaneProps> = ({ votings }) => {
  const { group, name, integration, custom } = useConfig();
  const ref = useRef<HTMLDivElement>(null);
  const slider = useRef<HTMLDivElement | null>(null);
  const votingCount = votings.length;
  const [isFirstItemVisible, setIsFirstItemVisible] = useState<boolean>(true);
  const [isLastItemVisible, setIsLastItemVisible] = useState<boolean>(false);
  const userVotes = useMemo(
    () =>
      votings
        .map((voting) => {
          const votingStep = getVotingStep(voting);
          const resultsStep = getResultsStep(voting);
          return ((votingStep ?? resultsStep)?.dimensions ?? []).find(
            (dimension) => dimension.userVote,
          );
        })
        .filter(Boolean),
    [votings],
  );

  const updateSliderState = (): void => {
    if (slider.current && slider.current.children.length) {
      setIsFirstItemVisible(
        Math.abs(
          slider.current.children[0].getBoundingClientRect().left -
            slider.current.getBoundingClientRect().left,
        ) < 10,
      );
      setIsLastItemVisible(
        slider.current.children[
          slider.current.children.length - 1
        ].getBoundingClientRect().right -
          slider.current.getBoundingClientRect().right <
          10,
      );
    }
  };

  const swipeHandler = (): void => {
    trackEvent('Voting Teaser Swipe', {
      group: String(group),
      label: 'Lane',
    });
    updateSliderState();
  };

  const debouncedScrollHandler = useMemo(
    () => debounce(swipeHandler, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goToItem = (index: number): void => {
    if (slider.current) {
      slider.current.scrollTo({
        left: (slider.current.children[index] as HTMLDivElement).offsetLeft,
        behavior: 'smooth',
      });
      updateSliderState();
      setTimeout(updateSliderState);
    }
  };

  const getCurrentItem = (): number =>
    Array.from(slider.current?.children ?? []).findIndex(
      (child) =>
        slider.current &&
        child.getBoundingClientRect().left >=
          slider.current.getBoundingClientRect().left &&
        child.getBoundingClientRect().left +
          child.getBoundingClientRect().width <
          slider.current.getBoundingClientRect().left +
            slider.current.getBoundingClientRect().width,
    );

  const goToPreviousItem = (): void => {
    goToItem(Math.max(0, getCurrentItem() - 1));
  };

  const goToNextItem = (): void => {
    goToItem(Math.min(votings.length - 1, getCurrentItem() + 1));
  };

  useEffect(() => {
    if (ref.current) {
      onVisibilityChange(ref.current, (isVisible) => {
        if (isVisible) {
          trackEvent('Voting Component Impression', {
            group: String(group),
            label: 'Lane',
          });
        }
      });
    }

    on('lane:scroll', (props) => {
      if (props?.votingId) {
        const index = votings.findIndex(
          (voting) => voting.flowId === props?.votingId,
        );
        goToItem(Math.max(0, index));
      }
    });

    updateSliderState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return votingCount > 0 ? (
    <LaneContext.Provider
      value={{
        count: votingCount,
      }}>
      <>
        <div
          ref={ref}
          className={classNames(styles.lane, {
            [styles[`lane--${integration}`]]: integration,
            [styles['lane--lengthy']]: votingCount > 3,
          })}>
          <Stack gap="lg">
            {custom?.laneIntro ? (
              <div className={styles['lane__intro']}>
                <Stack gap="sm">
                  <Bolt />
                  <Headline level={5}>
                    {name
                      ? interpolate(String(custom?.laneIntroHeadlineUser), {
                          name,
                          votingCount,
                        })
                      : interpolate(
                          String(custom?.laneIntroHeadlineAnonymous),
                          {
                            votingCount,
                          },
                        )}
                  </Headline>
                </Stack>
              </div>
            ) : null}
            <div className={styles['lane__slider']}>
              <div
                className={classNames(
                  styles['lane__control'],
                  styles['lane__control--previous'],
                )}>
                <LaneNavigator
                  direction="previous"
                  disabled={isFirstItemVisible}
                  onClick={() => {
                    goToPreviousItem();
                    trackEvent('Voting Teaser Lane Pagination Previous Click', {
                      group: String(group),
                      label: 'Lane',
                    });
                  }}
                />
              </div>
              <div
                ref={slider}
                className={styles['lane__items']}
                onScroll={debouncedScrollHandler}>
                {custom?.laneTeaserTitle &&
                custom?.laneTeaserSubline &&
                custom?.laneTeaserAction &&
                custom?.laneTeaserUrl ? (
                  <div
                    className={classNames(
                      styles['lane__item'],
                      styles['lane__item--teaser'],
                    )}>
                    <LaneTeaser
                      title={String(custom.laneTeaserTitle)}
                      subline={String(custom.laneTeaserSubline)}
                      action={String(custom.laneTeaserAction)}
                      url={String(custom.laneTeaserUrl)}
                    />
                  </div>
                ) : null}
                {votings.map((voting, index, array) => (
                  <div key={voting.flowId} className={styles['lane__item']}>
                    <LaneItem
                      voting={voting}
                      nextVoting={array[index + 1]}
                      index={index + 1}
                    />
                  </div>
                ))}
                {Array.from({ length: MIN_LANE_LENGTH - votingCount }).map(
                  (_, index) => (
                    <div key={index} className={styles['lane__item']}>
                      <LanePlaceholder />
                    </div>
                  ),
                )}
              </div>
              <div
                className={classNames(
                  styles['lane__control'],
                  styles['lane__control--next'],
                )}>
                <LaneNavigator
                  direction="next"
                  disabled={isLastItemVisible}
                  onClick={() => {
                    goToNextItem();
                    trackEvent('Voting Teaser Lane Pagination Next Click', {
                      group: String(group),
                      label: 'Lane',
                    });
                  }}
                />
              </div>
            </div>
          </Stack>
          <div className={styles.lane__indicators}>
            <div className={styles['lane__indicator-one']} />
            <div className={styles['lane__indicator-two']} />
            <div className={styles['lane__indicator-three']} />
            <div className={styles['lane__indicator-remainder']} />
          </div>
        </div>
      </>
      {custom && userVotes.length >= custom.laneFloatingTeaserAmount ? (
        <FloatingTeaser
          title={interpolate(String(custom.floatingTeaserTitle), {
            count: String(userVotes.length),
          })}
          action={String(custom.floatingTeaserAction)}
          image={String(custom.floatingTeaserImage)}
          onClick={() => window.open(String(custom.floatingTeaserUrl))}
        />
      ) : null}
    </LaneContext.Provider>
  ) : null;
};
