import { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Headline } from '../components/Headline';
import { Expand, Flip, getIcon, IconName } from './Icon';
import { getColorForImageElement } from '../utils/images';
import { on } from '../utils/bus';

import styles from './Card.module.scss';

export type CardApi = {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
  setColor: (newColor?: string) => void;
};

export type CardProps = {
  id?: string;
  flippable?: boolean;
  passive?: boolean;
  stretched?: boolean;
  coverImage?: string;
  icon?: IconName;
  topline?: string;
  label?: string;
  badge: string;
  cover?: (api: CardApi) => ReactNode;
  details?: (api: CardApi) => ReactNode;
  onCoverClick?: () => void;
  onFlip?: (isFlipped: boolean) => void;
};

export const Card: FC<CardProps> = ({
  id,
  flippable = true,
  passive,
  stretched,
  coverImage,
  icon,
  topline,
  label,
  badge,
  cover,
  details,
  onCoverClick,
  onFlip,
}) => {
  const [color, setColor] = useState<string | undefined>(undefined);
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const api: CardApi = {
    isFlipped,
    setIsFlipped,
    setColor,
  };
  const IconComponent = icon && getIcon(icon);

  useEffect(() => {
    on(`card:flip:${id}`, () => {
      setIsFlipped((state) => !state);
    });
  }, [id]);

  useEffect(() => {
    onFlip?.(isFlipped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlipped]);

  return (
    <div
      className={classNames(styles['card'], {
        [styles['card--flipped']]: isFlipped,
        [styles['card--clickable']]: flippable || onCoverClick,
        [styles['card--inactive']]: !(flippable || onCoverClick),
        [styles['card--passive']]: passive,
        [styles['card--stretched']]: stretched,
        [styles['card--plain']]: !coverImage,
        [styles['card--visual']]: !!coverImage,
      })}
      style={{
        '--color': color,
      }}>
      <div
        className={styles['card__cover']}
        onClick={() => {
          if (flippable && details) {
            setIsFlipped(true);
          }
          onCoverClick?.();
        }}>
        {coverImage ? (
          <div className={styles['card__visual']}>
            <img
              crossOrigin="anonymous"
              src={coverImage}
              alt={label}
              className={styles['card__image']}
              onLoad={async (e) => {
                const averageColorResult = await getColorForImageElement(
                  e.target as HTMLImageElement,
                );
                setColor(averageColorResult);
              }}
            />
          </div>
        ) : null}
        {IconComponent ? (
          <div className={styles['card__icon']}>
            <IconComponent />
          </div>
        ) : null}
        {flippable && details ? (
          <div className={styles['card__indicator']}>
            <Flip />
          </div>
        ) : onCoverClick ? (
          <div className={styles['card__indicator']}>
            <Expand />
          </div>
        ) : null}
        <div className={styles['card__content']}>
          {topline ? (
            <div className={styles['card__topline']}>{topline}</div>
          ) : null}
          {label ? (
            <div className={styles['card__label']}>
              <Headline level={5}>{label}</Headline>
            </div>
          ) : null}
          {cover ? cover(api) : null}
          <div className={styles['card__badge']}>
            <div className={styles['card__action']}>{badge}</div>
          </div>
        </div>
      </div>
      {details ? (
        <div
          className={styles['card__details']}
          onClick={(e) => {
            if (e.currentTarget === e.target) {
              setIsFlipped(false);
            }
          }}>
          <div className={styles['card__indicator']}>
            <Flip />
          </div>
          <div className={styles['card__details-headline']}>
            <Headline level={6} align="center">
              {label}
            </Headline>
          </div>
          {details(api)}
        </div>
      ) : null}
    </div>
  );
};
