import { FC } from 'react';
import { trackEvent } from '../utils/tracking';

export const LinkTracking: FC = ({ children }) => {
  return (
    <span
      onClick={(e) => {
        const target = e.nativeEvent.target;
        if (target instanceof HTMLAnchorElement) {
          trackEvent('Link Click', {
            group: 'Generic',
            label: `${(target.textContent ?? '').trim()} / ${
              target.getAttribute('href') ?? ''
            }`,
          });
        }
      }}>
      {children}
    </span>
  );
};
