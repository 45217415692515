// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SomFrame_som-frame__38eyd{width:100%;height:100%}", "",{"version":3,"sources":["webpack://src/components/SomFrame.module.scss"],"names":[],"mappings":"AAAA,2BACE,UAAA,CACA,WAAA","sourcesContent":[".som-frame {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"som-frame": "SomFrame_som-frame__38eyd"
};
export default ___CSS_LOADER_EXPORT___;
