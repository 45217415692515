// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanePlaceholder_lane-placeholder__3hRub{width:100%;height:0;padding-top:171.4285714286%;background:var(--ds-colors-surface-base-50);border-radius:var(--ds-radii-md)}", "",{"version":3,"sources":["webpack://src/components/LanePlaceholder.module.scss"],"names":[],"mappings":"AAIA,yCACE,UAAA,CACA,QAAA,CACA,2BAAA,CACA,2CAAA,CACA,gCAAA","sourcesContent":["@use 'sass:math';\n\n$lane-placeholder-ratio: math.div(528, 308);\n\n.lane-placeholder {\n  width: 100%;\n  height: 0;\n  padding-top: 100% * $lane-placeholder-ratio;\n  background: var(--ds-colors-surface-base-50);\n  border-radius: var(--ds-radii-md);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lane-placeholder": "LanePlaceholder_lane-placeholder__3hRub"
};
export default ___CSS_LOADER_EXPORT___;
