import { FC } from 'react';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from './Icon';

import styles from './LaneNavigator.module.scss';

export type LaneNavigatorDirection = 'previous' | 'next';

export type LaneNavigatorProps = {
  direction: LaneNavigatorDirection;
  onClick?: () => void;
  disabled?: boolean;
};

export const LaneNavigator: FC<LaneNavigatorProps> = ({
  direction,
  disabled,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        styles['lane-navigator'],
        styles[`lane-navigator--${direction}`],
        {
          [styles['lane-navigator--disabled']]: disabled,
        },
      )}
      onClick={() => (!disabled ? onClick?.() : undefined)}>
      <span className={styles['lane-navigator__icon']}>
        {direction === 'previous' ? (
          <ChevronLeft size={20} />
        ) : (
          <ChevronRight size={20} />
        )}
      </span>
    </button>
  );
};
