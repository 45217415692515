import { VotingStepTypeKey } from '@data/eve-sdk-web';
import { createContext, useContext } from 'react';

export type Step = VotingStepTypeKey | 'Splash';

export type StepApi = {
  steps: Step[];
  step: Step;
  previousStep: Step;
  nextStep: Step;
  setStep: (newStep: Step) => void;
  goToStart: () => void;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
};

export const StepContext = createContext<StepApi>({
  steps: ['Splash'],
  step: 'Splash',
  previousStep: 'Splash',
  nextStep: 'Splash',
  setStep: () => undefined,
  goToStart: () => undefined,
  goToPreviousStep: () => undefined,
  goToNextStep: () => undefined,
});

export const useStep = (): StepApi => useContext(StepContext);
