"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeVote = exports.addVote = exports.subscribe = exports.setUser = exports.setup = void 0;
var EVE_SDK_VERSION = '0.5.0';
var EVE_SDK_CONFIGS = {
    int: "https://eve-int.p7s1.io/sdk/config/config.v".concat(EVE_SDK_VERSION, ".json"),
    preprod: "https://eve-preprod.p7s1.io/sdk/config/config.v".concat(EVE_SDK_VERSION, ".json"),
    prod: "https://eve.p7s1.io/sdk/config/config.v".concat(EVE_SDK_VERSION, ".json"),
};
var waitForEveApi = function (check, success, timeout, iterations) {
    if (iterations === void 0) { iterations = 500; }
    if (check()) {
        success();
    }
    else {
        if (iterations > 0) {
            setTimeout(function () {
                waitForEveApi(check, success, timeout, iterations - 1);
            }, 20);
        }
        else {
            timeout === null || timeout === void 0 ? void 0 : timeout();
        }
    }
};
var setup = function (env, user) { return __awaiter(void 0, void 0, void 0, function () {
    var config;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, loadConfig(EVE_SDK_CONFIGS[env])];
            case 1:
                config = _b.sent();
                return [4 /*yield*/, loadBundle(config)];
            case 2:
                _b.sent();
                if ((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.setup) {
                    globalThis.eveSdkJsApi.setup({
                        webSocketUrl: config.webSocketUrl,
                        eveApiUrl: config.eveApiUrl,
                        eveAccessKey: config.eveAccessKey,
                    }, user);
                }
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        waitForEveApi(function () { var _a; return Boolean((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.subscribe); }, resolve, reject);
                    })];
        }
    });
}); };
exports.setup = setup;
var setUser = function (user) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) {
                waitForEveApi(function () { var _a; return Boolean((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.setUser); }, function () {
                    var _a;
                    if ((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.setUser) {
                        globalThis.eveSdkJsApi.setUser(user);
                        resolve();
                    }
                    else {
                        console.error('Cannot update user/token yet. Finish the setup first.');
                        reject();
                    }
                }, reject);
            })];
    });
}); };
exports.setUser = setUser;
var loadConfig = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response, config, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(url)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                config = (_a.sent());
                return [2 /*return*/, config];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 4: return [2 /*return*/];
        }
    });
}); };
var loadBundle = function (config) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!config.libraryUrl) {
            return [2 /*return*/, Promise.reject(new Error('The config is missing the library URL.'))];
        }
        return [2 /*return*/, new Promise(function (resolve, reject) {
                var id = "eveSdkJsApi_".concat(config.libraryVersion);
                if (!document.getElementById(id)) {
                    var script = document.createElement('script');
                    script.src = config.libraryUrl;
                    script.id = id;
                    script.onload = function () {
                        return waitForEveApi(function () { var _a; return Boolean((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.setup); }, resolve);
                    };
                    script.onerror = reject;
                    document.head.appendChild(script);
                }
                else {
                    resolve();
                }
            })];
    });
}); };
var subscribe = function (context, callback, options) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveSdkJsApi) === null || _a === void 0 ? void 0 : _a.subscribe)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveSdkJsApi.subscribe(context, callback, options)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot subscribe yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.subscribe = subscribe;
var addVote = function (context, bucketId, dimension, value) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((_b = globalThis.eveSdkJsApi) === null || _b === void 0 ? void 0 : _b.addVote)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveSdkJsApi.addVote(context, bucketId, (_a = {},
                        _a[dimension] = value,
                        _a))];
            case 1:
                _c.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot vote yet. Finish the setup first.');
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.addVote = addVote;
var removeVote = function (context, bucketId, dimension, value) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((_b = globalThis.eveSdkJsApi) === null || _b === void 0 ? void 0 : _b.removeVote)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveSdkJsApi.removeVote(context, bucketId, (_a = {},
                        _a[dimension] = value,
                        _a))];
            case 1:
                _c.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot remove vote yet. Finish the setup first.');
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.removeVote = removeVote;
__exportStar(require("./types"), exports);
