import { FC } from 'react';
import { Voting } from '@data/eve-sdk-web';
import { getResultsStep } from '../utils/votings';

import { useStep } from '../utils/step';
import { Modal } from '../components/Modal';
import { Button } from '../components/Button';
import { Headline } from '../components/Headline';
import { Stack } from '../components/Stack';
import { Results } from '../components/Results';
import { getImageUrl } from '../utils/images';
import { Ranking } from '../components/Ranking';
import { useConfig } from '../utils/config';

export type ResultProps = {
  voting: Voting;
};

export const Result: FC<ResultProps> = ({ voting }) => {
  const { step, goToStart, goToNextStep } = useStep();
  const { name } = useConfig();
  const results = getResultsStep(voting);
  const selectedItem = (results?.dimensions ?? []).find(
    (dimension) => dimension.userVote,
  )?.id;
  const ranked = Array.from(results?.dimensions ?? []).sort(
    (a, b) => (b.result?.number ?? 0) - (a.result?.number ?? 0),
  );

  return (
    <Modal
      id="results"
      open={step === 'Results'}
      onClose={() => goToStart()}
      footer={<Button label="Schließen" onClick={() => goToNextStep()} />}>
      <Stack variant="medium">
        {voting.type === 'battles' ? (
          <>
            <Headline level={4} align="center">
              {name
                ? `${name}, ${ranked[0].title} hat diese Runde gewonnen.`
                : `${ranked[0].title} hat diese Runde gewonnen.`}
            </Headline>
            <Headline level={6} align="center">
              {selectedItem === ranked[0].id
                ? 'Dein Favorit hat es geschafft!'
                : 'Schade, dass es dein Favorit nicht geschafft hat.'}
            </Headline>
          </>
        ) : (
          <>
            <Headline level={4} align="center">
              {voting.title}
            </Headline>
            <Headline level={6} align="center">
              So lauten die Favoriten!
            </Headline>
          </>
        )}
        <Stack variant="narrow">
          {voting.type === 'battles' ? (
            <Ranking
              items={ranked.map(({ id, image, title }) => ({
                image: image?.url
                  ? getImageUrl(image.url, 'square')
                  : undefined,
                label: title,
                active: selectedItem === id,
              }))}
            />
          ) : (
            <Results
              items={(results?.dimensions ?? []).map(
                ({ id, image, title, result }) => ({
                  image: image?.url
                    ? getImageUrl(image.url, 'square')
                    : undefined,
                  label: title,
                  active: selectedItem === id,
                  share: (result?.percentage ?? 0).toFixed(0),
                }),
              )}
            />
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
